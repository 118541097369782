import React from "react";
import Layout from "../layouts/PageLayout";
import vision from "../assets/images/vision.png";
import mission from "../assets/images/mission.png";

function Aboutus() {
  return (
    <Layout className="w-screen">
      <div class="lg:hidden">
        <div className="w-full px-6">
          <div className="my-7 text-center">
            <span className="text-[#00ADB5] text-2xl   font-bold font-sans">
              ABOUT US
            </span>
            <div className="w-[130px] h-[4px] bg-[#00ADB5] my-1 mx-auto"></div>
          </div>
          <div>
            <p className="mb-5 text-sm indent-14  lg:text-base font-medium font-sans text-justify text-[#393E46]">
              Chennai Cabs is a prominent name in the field of Staff
              Transportation Services, catering to the needs of the most reputed
              customers in major cities of Tamil Nadu, Karnataka, Telangana, and
              Andhra Pradesh. Established in 2016 as a small business entity
              serving Industry Houses, we have since expanded our services to
              major industrial giants and corporate houses.
            </p>
            <p className="mb-5 text-sm  lg:text-base font-medium text-justify text-[#393E46]">
              Our fleet of over 820 vehicles, including AC and non-AC cabs, maxi
              cabs, and tempo travellers, cater to both individual and
              industrial requirements. We have multiple maintenance yards and
              diesel filling facilities in the vicinity of our customers' plant
              locations, along with parking yards that enable us to transport
              our customers' staff in a timely and efficient manner.
            </p>
            <p className="mb-5 text-sm  lg:text-base font-medium text-justify text-[#393E46]">
              Our dedicated service has made us a major stakeholder in the
              smooth operations of these corporate houses by ensuring that their
              manpower reaches its destination on time, every time. Our own
              premises, equipped with a separate tracking system of all vehicles
              in a single location and dedicated qualified heads for different
              disciplines, ensure that we provide quality services to our
              clients.
            </p>
            <p className=" text-sm lg:text-base font-medium text-justify text-[#393E46]">
              We prioritise the safety of our staff members, and conduct regular
              road safety training and medical check-ups. With our dedicated
              service and stable infrastructure, we assure our corporate
              clientele of outstanding professional service, making us a major
              staff transportation operator for corporates in Tamil Nadu, Andhra
              Pradesh, Karnataka, and Telangana.
            </p>
          </div>
          <div className="flex flex-wrap items-center w-full my-10">
            <div className="w-full">
              <div className="mb-2">
                <span className="text-[#00ADB5] text-2xl font-bold font-sans">
                  OUR VISION
                </span>
                <div className="w-[160px] h-[4px] bg-[#00ADB5] "></div>{" "}
              </div>
            </div>
            <div className="w-full ">
              <img
                className="w-[250px]  h-[220px] mx-auto my-auto"
                src={vision}
                alt="vision-icon"
              />
              <div>
                <p className=" text-sm lg:text-base font-medium text-justify text-[#393E46]">
                  Our vision is to offer safe, reliable, and affordable
                  transportation services to all our customers. We aim to
                  achieve this through continuous innovation and the integration
                  of new technologies to provide a seamless customer experience.
                  Our ultimate goal is to become the preferred choice of
                  transportation for all our customers.
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap items-center w-full mb-10">
            <div className="w-full ">
              <div className="flex justify-end ">
                <span className="text-[#00ADB5] text-2xl font-bold font-sans">
                  OUR MISSION
                </span>
              </div>
              <div className="flex justify-end w-full">
                <div className="w-[160px] h-[4px] mb-2 bg-[#00ADB5] "></div>
              </div>
              <div>
                <img
                  className="w-[250px] h-[220px] mx-auto my-auto"
                  src={mission}
                  alt="mission-icon"
                />
              </div>
            </div>
            <div className="w-full">
              <p className=" lg:text-base font-medium text-justify text-sm text-[#393E46] ">
                Our mission is to provide our customers with the best possible
                transportation services, tailored to their needs and
                preferences. We strive to exceed our customers' expectations by
                delivering exceptional customer service, innovative technology,
                and well-maintained vehicles.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="hidden lg:block">
        <div className="px-10">
          <div className="my-10 text-center">
            <span className="text-[#00ADB5] text-2xl font-bold font-sans">
              ABOUT US
            </span>
            <div className="w-[125px] h-[4px] bg-[#00ADB5]  mx-auto"></div>
          </div>
          <div>
            <p className="mb-5 indent-14 text-base font-medium font-sans text-justify text-[#393E46]">
              Chennai Cabs is a prominent name in the field of Staff
              Transportation Services, catering to the needs of the most reputed
              customers in major cities of Tamil Nadu, Karnataka, Telangana, and
              Andhra Pradesh. Established in 2016 as a small business entity
              serving Industry Houses, we have since expanded our services to
              major industrial giants and corporate houses.
            </p>
            <p className="mb-5 text-base font-medium text-justify text-[#393E46]">
              Our fleet of over 820 vehicles, including AC and non-AC cabs, maxi
              cabs, and tempo travellers, cater to both individual and
              industrial requirements. We have multiple maintenance yards and
              diesel filling facilities in the vicinity of our customers' plant
              locations, along with parking yards that enable us to transport
              our customers' staff in a timely and efficient manner.
            </p>
            <p className="mb-5 text-base font-medium text-justify text-[#393E46]">
              Our dedicated service has made us a major stakeholder in the
              smooth operations of these corporate houses by ensuring that their
              manpower reaches its destination on time, every time. Our own
              premises, equipped with a separate tracking system of all vehicles
              in a single location and dedicated qualified heads for different
              disciplines, ensure that we provide quality services to our
              clients.
            </p>
            <p className="text-base font-medium text-justify text-[#393E46]">
              We prioritise the safety of our staff members, and conduct regular
              road safety training and medical check-ups. With our dedicated
              service and stable infrastructure, we assure our corporate
              clientele of outstanding professional service, making us a major
              staff transportation operator for corporates in Tamil Nadu, Andhra
              Pradesh, Karnataka, and Telangana.
            </p>
          </div>
          <div className="flex items-center w-full my-10">
            <div className="w-4/12">
              <img
                className="w-[250px] h-[220px] mx-auto my-auto"
                src={vision}
                alt="vision-icon"
              />
            </div>
            <div className="w-8/12">
              <div className="mb-2">
                <span className="text-[#00ADB5] text-2xl font-bold font-sans">
                  OUR VISION
                </span>
                <div className="flex justify-start w-full">
                  <div className="w-[155px] mb-2 h-[4px] bg-[#00ADB5]  "></div>
                </div>
              </div>
              <div>
                <p className="text-base font-medium text-justify text-[#393E46]">
                  Our vision is to offer safe, reliable, and affordable
                  transportation services to all our customers. We aim to
                  achieve this through continuous innovation and the integration
                  of new technologies to provide a seamless customer experience.
                  Our ultimate goal is to become the preferred choice of
                  transportation for all our customers.
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center w-full mb-10">
            <div className="w-8/12 ">
              <div className="flex justify-end ">
                <span className="text-[#00ADB5] text-2xl font-bold font-sans">
                  OUR MISSION
                </span>
              </div>
              <div className="flex justify-end w-full">
                <div className="w-[160px] mb-2 h-[4px] bg-[#00ADB5]  "></div>
              </div>
              <div>
                <p className="text-base font-medium text-justify text-[#393E46] ">
                  Our mission is to provide our customers with the best possible
                  transportation services, tailored to their needs and
                  preferences. We strive to exceed our customers' expectations
                  by delivering exceptional customer service, innovative
                  technology, and well-maintained vehicles.
                </p>
              </div>
            </div>
            <div className="w-4/12">
              <img
                className="w-[250px] h-[220px] mx-auto my-auto"
                src={mission}
                alt="mission-icon"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Aboutus;
