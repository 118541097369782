import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import banner1 from "../assets/images/Banner1.png";
import banner2 from "../assets/images/Banner2.png";
import banner3 from "../assets/images/Banner3.png";
import banner4 from "../assets/images/Banner4.jpg";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Link } from "react-router-dom";

export default function BannerSlider() {
  const swiperSlides = [
    {
      src: banner4,
      altText: "Slide 1",
    },
    {
      src: banner1,
      altText: "Slide 2",
    },
    {
      src: banner2,
      altText: "Slide 3",
    },
    {
      src: banner3,
      altText: "Slide 4",
    },
  ];
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {swiperSlides.map((slide, index) => {
          return (
            <>
              <SwiperSlide key={index}><Link to={"https://play.google.com/store/apps/details?id=com.cabs.chennaicabs"} target="_blank"><img src={slide.src} alt={slide.altText} className="w-full max-h-[40vh] object-cover" /></Link></SwiperSlide>
            </>
          )
        })}
      </Swiper>
    </>
  );
}
