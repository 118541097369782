import React, { useState } from "react";

export default function Testimonialpage2() {
  const testimonials = [
    {
      content:
        "Excellent cab in my travel experience. On time pick up and professional drivers. Safety wise good one. Overall nice travel experience with Chennai cabs. Would recommend to my friends and family.",
      author: "Karthi Keyan",
    },
    {
      content:
        "It is a very good service. Cabs are easily available and on time drivers are very professional and punctual. Highly recommended.",
      author: "Janani S M",
    },
    {
      content:
        "Felt very safe and secured to travel as a woman passenger. I recommend this cab service as economical as well as fully sanitized during this pandemic time. The driver was very patient and well behaved. I m fully satisfied with the service.",
      author: "Bhuvana Jain",
    },
    {
      content:
        "I have travelled from Madurai to Bangalore, it's a wonderful experience. Nice driver with well maintained vehicles. I travelled with so many operators, but Chennai Cab given a wonderful service with affordable cost. Thank you Chennai Cab.",
      author: "Saravanan",
    },
    {
      content:
        "Booked one way trip from kalayarkoil to chennai. Reasonable price in comfortable car. Driver also friendly and supported in all the ways. Kudos to Chennai cabs for wonderful service",
      author: "Thandaiah Prabu",
    },
    {
      content:
        "Had a great drive.. it's safe and secure... Chennai cabs is most likely to be preferable for ur journeys. Drivers ensure the customers safety.. cabs are sanitized often.. Really had a great experience with Chennai cabs",
      author: "Scinthia Clarinda",
    },
    {
      content:
        "I have booked cab for Chennai to vellore..They charged only Rs2999 only for round trip..i have saved rs400 compare than other travels..safe journey,nice driving,good condition car and value for money.they provided excellent service.",
      author: "Gajendhiran S",
    },
    {
      content:
        "Really nice experience all over the trip! Cab driver was well experienced in tourist taxi and helped us a lot.",
      author: "Sabarish",
    },
    {
      content:
        "Very seamless experience from booking through WhatsApp chat, on-time update from the driver, and the comfortable journey.",
      author: "Periyasamy Thoppalan",
    },
    {
      content:
        "Good service.. Chennai Cabs is providing excellent service.. Their cabs are clean.. Cost-wise reasonable.. I have used their one-way drop and two-way service.. I am happy with their service..Thanks Chennai Cabs..",
      author: "K Madhan Kumar",
    },
    {
      content:
        "Good service and reasonable fare compared with other travels.. Highly recommended.. Thanking you",
      author: "Antony Charles",
    },
    {
      content:
        "Booked one way trip from Coimbatore to Chennai. The journey was very comfortable and reasonable fare. I am very happy to use Chennai Cabs..",
      author: "Dayana",
    },
  ];

  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentTestimonialIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentTestimonialIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const currentTestimonial = testimonials[currentTestimonialIndex];

  return (
    <div>
      <section className="bg-[#393E46] mx-2 my-5 rounded-lg">
        <div className="px-4 py-10 sm:px-6 sm:py-24 lg:me-0 lg:pe-0 lg:ps-8">
          <div className="grid grid-cols-1 gap-y-8 lg:flex lg:justify-center lg:gap-4 lg:px-4 lg:w-full lg:items-center">
            <div className="max-w-[450px] text-center lg:text-start ltr:sm:text-left rtl:sm:text-right">
              {/* <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
                Don't just take our word for it...
                <br className="hidden sm:block lg:hidden" />
                Read reviews from our customers
              </h2> */}
              <h2 className="text-3xl font-bold tracking-tight sm:text-3xl text-[#00ADB5]">
                Explore experiences from our valued customers, their reviews speak louder than our words.
              </h2>
              <div className="hidden lg:mt-8 lg:flex lg:gap-4">
                <button
                  onClick={handlePrevious}
                  className="prev-button rounded-full border border-[#00ADB5] p-3 text-[#00ADB5] hover:bg-[#00ADB5] hover:text-white"
                >
                  <span className="sr-only">Previous Slide</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-5 h-5 rtl:rotate-180"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 19.5L8.25 12l7.5-7.5"
                    />
                  </svg>
                </button>

                <button
                  onClick={handleNext}
                  className="next-button rounded-full border border-[#00ADB5] p-3 text-[#00ADB5] hover:bg-[#00ADB5] hover:text-white"
                >
                  <span className="sr-only">Next Slide</span>
                  <svg
                    className="w-5 h-5 rtl:rotate-180"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 19.5L15.75 12l-7.5-7.5"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="">
              <div className="relative">
                <blockquote className="mt-10 lg:mt-0">
                  <div className="max-w-3xl mx-auto text-center">
                    <p className="text-xl font-medium text-white">
                      "{currentTestimonial.content}"
                    </p>
                    <p className="mt-5 text-base text-gray-300">
                      - {currentTestimonial.author}
                    </p>
                  </div>
                </blockquote>
              </div>
            </div>
            <div className="flex justify-center gap-4 mt-8 lg:hidden">
              <button
                onClick={handlePrevious}
                aria-label="Previous slide"
                className="prev-button rounded-full border border-[#00ADB5] p-4 text-[#00ADB5] hover:bg-[#00ADB5] hover:text-white"
              >
                <svg
                  className="w-5 h-5 transform -rotate-180"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 5l7 7-7 7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </svg>
              </button>

              <button
                onClick={handleNext}
                aria-label="Next slide"
                className="next-button rounded-full border border-[#00ADB5] p-4 text-[#00ADB5] hover:bg-[#00ADB5] hover:text-white"
              >
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 5l7 7-7 7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
