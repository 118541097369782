import { Layout } from 'antd';
import React, { useEffect } from 'react';
import Header from '../layouts/Navbar';
import Footer from '../layouts/Footer';

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <Header />
      <div className="container mt-5 mx-auto px-4 pb-10">
        <h1 className="text-3xl font-bold my-4 text-red-500">Terms and Conditions</h1>

        <section>
          <h2 className="text-lg font-semibold  mt-6 mb-[6px]">Introduction</h2>
          <p>Welcome to the Chennai Cabs website. By continuing to browse and use this website, you agree to comply with and be bound by the following terms and conditions of use, which, together with our privacy policy, govern Chennai Cabs' relationship with you concerning this website. If you disagree with any part of these terms and conditions, please refrain from using our website.</p>
        </section>

        <section>
          <h2 className="text-lg font-semibold mt-6 mb-[6px]">Ownership</h2>
          <p>The term 'Chennai Cabs' or 'us' or 'we' refers to the entity providing cab services, whose registered office is 'Chennai Cabs, Plot No.11, Kandan Avenue, 24th Street 2nd Cross Street, J B Estate, Avadi, Chennai, Thiruvallur, Tamil Nadu, 600054. GSTIN: 33EAYPS1903L2Z2' The term 'you' refers to the user or viewer of our website.</p>
        </section>


        <section>
          <h2 className="text-lg font-semibold  mt-6 mb-2 ">Terms of Use</h2>
          <ol className="list-decimal pl-5 space-y-1">
            <li>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</li>
            <li>This website uses cookies to monitor browsing preferences. If you allow cookies to be used, the following personal information may be stored by us for use by third parties: IP Address, Location.</li>
            <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness, or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors, and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
            <li>Your use of any information or materials on this website is entirely at your own risk, and it shall be your responsibility to ensure that any products, services, or information available through this website meet your specific requirements.</li>
            <li>This website contains material owned by or licensed to us. Reproduction is prohibited other than in accordance with the copyright notice,
              which forms part of these terms and conditions.</li>
            <li>All trademarks reproduced on this website that are not the property of, or licensed to, the operator are acknowledged on the website.</li>
            <li>Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offence.</li>
            <li>From time to time, this website may include links to other websites. These links are provided for your convenience to provide further information.
              They do not signify that we endorse the website(s), and we have no responsibility for the content of the linked website(s).</li>
            <li>Your use of this website and any dispute arising out of such use is subject to the laws of India.</li>
            <li>Specific offers may have additional Terms & Conditions, which the user must comply with if choosing to avail that offer.</li>

          </ol>
        </section>

      </div>
      <Footer />
    </Layout>
  );
};

export default PrivacyPolicy;
