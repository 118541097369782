import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Landing";
import Aboutus from "./pages/AboutUs";
import Ourservices from "./pages/OurServices";
import Testimonial from "./pages/Testimonial";
import Contactus from "./pages/ContactUs";
import OneWayTrip from "./pages/OneWayTrip";
import Roundtrip from "./pages/RoundTrip";
import Testimonialpage2 from "./pages/Testimonial2";
import { ThemeProvider } from "./components/UserContext";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import RentalTrip from "./pages/RentalTrip";
import LocalTrip from "./pages/LocalTrip";
import PrivacyPolicyForMobile from "./pages/PrivacyPolicyForMobile";
import { MapLocationProvider } from "./services/context/MapLocation";

function App() {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <MapLocationProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/About-Us" element={<Aboutus />} />
            <Route path="/Our-Services" element={<Ourservices />} />
            <Route path="/Testimonial" element={<Testimonial />} />
            <Route path="/TestimonialTest" element={<Testimonialpage2 />} />
            <Route path="/Contact-Us" element={<Contactus />} />
            <Route path="/OneWay-Trip" element={<OneWayTrip />} />
            <Route path="/Round-Trip" element={<Roundtrip />} />
            <Route path="/Rental-Trip" element={<RentalTrip />} />
            <Route path="/Local-Trip" element={<LocalTrip />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
            <Route path="/RefundPolicy" element={<RefundPolicy />} />
            <Route path="/app/privacy-policy" element={<PrivacyPolicyForMobile />} />
          </Routes>
        </MapLocationProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
