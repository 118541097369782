import React from "react";
function Servicecard(props) {
  return (
    <div className="w-full h-[450px] mr-2 bg-[#222831] rounded-xl md:hover:transform md:hover:scale-105 transition duration-300 ease-in-out">
      <img
        className="object-cover rounded-t-xl "
        src={props.imgSrc}
        alt={props.alt}
      />

      <div className="mt-2">
        <span className="text-[#00ADB5] text-center flex justify-center items-center mt-3  text-lg font-medium font-sans">
          {props.span}
        </span>
        <p className="p-3 px-4 text-sm text-justify text-white indent-4">
          {props.paragraph}
        </p>
      </div>
    </div>
  );
}

export default Servicecard;