import React, { createContext, useState, useContext } from "react";

const MapLocation = createContext();

export const MapLocationProvider = ({ children }) => {
    const [pickupLocationMapUrl, setPickupLocationMapUrl] = useState("");
    const [dropLocationMapUrl, setDropLocationMapUrl] = useState("");

    return (
        <MapLocation.Provider
            value={{ pickupLocationMapUrl, setPickupLocationMapUrl, dropLocationMapUrl, setDropLocationMapUrl }}
        >
            {children}
        </MapLocation.Provider>
    );
};

export const useMapLocationUrl = () => useContext(MapLocation);
