import { useEffect } from "react";
import { BiTimeFive } from "react-icons/bi";

export default function SelectTimeInput({
    disabled,
    readOnly,
    value,
    setValue,
    placeholder,
    width,
    error,
    prePickUpTime
}) {

    useEffect(() => {
        if (prePickUpTime !== null) return;

        const currentDate = new Date();
        let currentHour = currentDate.getHours();
        let currentMinute = currentDate.getMinutes();
        currentMinute = Math.ceil(currentMinute / 15) * 15;
        if (currentMinute === 60) {
            currentMinute = 0;
            currentHour += 1;
        }
        if (currentHour === 24) {
            currentHour = 0;
        }
        const currentHourFormatted = currentHour < 10 ? `0${currentHour}` : currentHour;
        const currentMinuteFormatted = currentMinute < 10 ? `0${currentMinute}` : currentMinute;

        setValue(`${currentHourFormatted}:${currentMinuteFormatted}`);
    }, []);

    const times = (() => {
        const timesArray = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
                const period = hour < 12 ? "AM" : "PM";
                const formattedHour12 = hour % 12 || 12;
                const formattedHour24 = hour < 10 ? `0${hour}` : hour;
                const formattedMinute = minute === 0 ? '00' : minute;
                timesArray.push({
                    label: `${formattedHour12}:${formattedMinute} ${period}`,
                    value: `${formattedHour24}:${formattedMinute}`,
                });
            }
        }
        return timesArray;
    })();

    const handleTimeChange = (e) => {
        const newValue = e.target.value;
        setValue(newValue);
    };

    const inputStyle = {
        width: width || "100%",
    };

    return (
        <div className="flex flex-col max-w-[300px] min-w-[200px]" style={{ width: inputStyle.width }}>
            <div className={` ${!readOnly && !disabled && "cursor-pointer"} ${!value && error ? "border-red-400" : "border-border-gray"} ${disabled && "bg-[#eee]"} h-[40px]  my-time-picker  rounded-lg w-[250px] mr-1 text-[14px] bg-white  outline-none  border-none  text-[#393E46] text-md`} style={{ position: 'relative' }}>
                <BiTimeFive className="text-2xl custom-icon" style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: '10px' }} />
                <select
                    id="timeSelect"
                    disabled={disabled || readOnly}
                    value={value}
                    onChange={handleTimeChange}
                    className="w-full relative pl-10 pr-2 h-[40px] appearance-none bg-transparent border-none rounded-lg text-[#393E46] text-md focus:outline-none font-sans font-medium"
                >
                    <option value="" disabled={!value}>{placeholder}</option>
                    {times.map((time, index) => (
                        <option key={index} value={time.value}>{time.label}</option>
                    ))}
                </select>
            </div>
            {!value && error && (
                <p className="mt-1 text-sm text-red-500">
                    {error}
                </p>
            )}
        </div>
    );
}