import React, { useEffect, useRef, useState } from "react";
import Layout from "../layouts/PageLayout";
import TripInput from "../components/TripInput";
import Servicecard from "../components/HomeServiceCard";
import homeservice from "../assets/images/homeservice.jpg";
import localRentals from "../assets/images/localRental2.jpg";
import oneWay from "../assets/images/oneWay.jpg";
import marriage from "../assets/images/marriage.jpg";
import roundTrip from "../assets/images/roundTrip.jpg";
import lnt from "../assets/images/lnt.png";
import r1 from "../assets/images/R1.png";
import lapise from "../assets/images/lapise.png";
import medico from "../assets/images/Medico.png";
import websitebanner from "../assets/images/websitebanner.png";
import websitebanner1 from "../assets/images/websitebanner_mobile/websitebanner1.png";
import websitebanner2 from "../assets/images/websitebanner_mobile/websitebanner2.png";
import websitebanner3 from "../assets/images/websitebanner_mobile/websitebanner3.png";
import websitebanner4 from "../assets/images/websitebanner_mobile/websitebanner4.png";
import websitebanner5 from "../assets/images/websitebanner_mobile/websitebanner5.png";
import SimpleSlider from "../components/ServiceSlider";
import Testimonialpage2 from "./Testimonial2";
import BannerSlider from "../components/BannerSlider";
import Marquee from "react-fast-marquee";
import { motion, useAnimate, stagger } from "framer-motion";

function Home() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollRef = useRef(null);

  const websiteBanners = [
    websitebanner1, websitebanner2, websitebanner3, websitebanner4, websitebanner5
  ];
  const serviceCardsData = [
    {
      imgSrc: localRentals,
      alt: "homeservice-icon",
      span: "LOCAL RENTALS",
      paragraph: "Our local rental service offers well-maintained cars and courteous drivers for hassle-free travel in the city. Whether you need to visit multiple places or explore the city, we have got you covered at affordable rates."
    },
    {
      imgSrc: oneWay,
      alt: "homeservice-icon",
      span: "ONE WAY DROP",
      paragraph: "Our one-way drop service allows you to travel from one location to another with ease and comfort. You can choose from a wide range of well-maintained cars and experienced drivers to ensure a safe and on-time journey."
    },
    {
      imgSrc: roundTrip,
      alt: "homeservice-icon",
      span: "ROUND TRIP",
      paragraph: "Our round trip cab service offers you the convenience of booking a cab for a full day or multiple days. With well-maintained cars and experienced drivers, you can explore the city or travel to multiple destinations at your own pace and comfort."
    },
    {
      imgSrc: homeservice,
      alt: "homeservice-icon",
      span: "EMPLOYEE FLEET",
      paragraph: "Our corporate fleet service provides customized transportation solutions for businesses, ensuring safe and comfortable travel for employees and clients. With a range of well-maintained cars and experienced drivers."
    },
    {
      imgSrc: marriage,
      alt: "homeservice-icon",
      span: "WEDDING PACKAGE",
      paragraph: "Our wedding package offers a range of well-decorated cars and experienced drivers to add a touch of elegance to your special day. With customizable packages to suit your needs, you can rely on us to provide hassle-free and memorable for your wedding party."
    }
  ];

  return (
    <Layout>
      {/* <HomeCarousel /> */}
      <div className="h-full w-full">
        <BannerSlider />
      </div>
      <div className="w-full h-full ">
        <div className="">
          <motion.div
            className="bg-[#F8F9F9] px-5  py-1 lg:px-10"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
          >
            <TripInput />
          </motion.div>
          <div
            className="bg-[#222831] md:bg-[#fff]  py-1"
          >
            <div className="my-10 lg:my-10 hidden md:block">
              <img
                className="mx-auto rounded-2xl"
                src={websitebanner}
                alt="websitebanner-icon"
              />
            </div>
            <div className="flex md:hidden flex-col justify-center items-center">
              <p className="font-bold text-2xl text-white mt-4">Why Choose <span className="text-[#00ADB5]">Chennai Cabs?</span></p>
              <div
                className="my-10 lg:my-10 flex flex-wrap justify-center items-center"
              >
                {websiteBanners.map((banner, index) => (
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 0.4, delay: index * 0.1, ease: 'easeInOut' }}
                    viewport={{ once: true }}
                  >
                    <img
                      key={index}
                      className="mx-auto h-[12rem] rounded-2xl"
                      src={banner}
                      alt={`websitebanner-icon-${index}`}
                    />
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
          <div className="py-1 bg-[#F8F9F9]">
            <div className=" lg:mt-6">
              <div className="my-5 text-center lg:my-10 ">
                <span className="text-[#00ADB5] text-2xl font-bold font-sans">
                  OUR SERVICES
                </span>
                <div className="flex justify-center w-full">
                  <div className="w-[162px] mb-2 h-[4px] bg-[#00ADB5]  "></div>
                </div>
              </div>
              {isMobile ? (
                <div className="px-6">
                  <SimpleSlider />
                </div>
              ) : (
                <div className="flex w-full text-center px-4">
                  {serviceCardsData.map((card, index) => (
                    <motion.div
                      key={index}
                      className="w-1/5 m-1"
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{ duration: 0.1, delay: index * 0.2, ease: 'easeInOut' }}
                      viewport={{ once: true }}
                      whileHover={{
                        scale: 1.03,
                        transition: { duration: 0.1, ease: 'linear' },
                      }}
                    >
                      <Servicecard
                        className="h-[300px]"
                        imgSrc={card.imgSrc}
                        alt={card.alt}
                        span={card.span}
                        paragraph={card.paragraph}
                      />
                    </motion.div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="bg-[#fff]  py-1">
            <div className="my-10 lg:my-20">
              <div className="my-2 text-center lg:my-10">
                <span className="text-[#00ADB5] text-2xl font-bold font-sans">
                  OUR CLIENTS
                </span>
                <div className="flex justify-center w-full">
                  <div className="w-[160px] mb-2 h-[4px] bg-[#00ADB5] "></div>
                </div>
              </div>
              <div className="flex w-full mb-10">
                <Marquee
                  pauseOnHover
                  speed={100}
                >
                  <img src={lnt} alt="lnt-logo" className="w-[400px]" />
                  <img src={r1} alt="r1-logo" className="w-[400px]" />
                  <img src={lapise} alt="lapise-logo" className="w-[400px]" />
                  <img src={medico} alt="medico-logo" className="w-[400px]" />
                </Marquee>
              </div>
            </div>
            <div className="py-1 bg-[#F8F9F9]">
              <Testimonialpage2 />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Home;
