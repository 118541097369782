import React, { useEffect, useRef, useState, useTransition } from "react";
import taxiicon from "../assets/images/taxi-icon.png";
import location from "../assets/images/location-icon.png";
import calender from "../assets/images/calendar-icon.png";
import { useLocation, useNavigate } from "react-router-dom";
import { BiTimeFive } from "react-icons/bi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-clock/dist/Clock.css";
import "react-time-picker/dist/TimePicker.css";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "./Time.css";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import DateUtils from "../services/util/DateUtils"
import TimeInput from "./TimeInput";
import distanceIcon from "../assets/icon/distance.svg";
import durationIcon from "../assets/icon/duration.svg";
import iIcon from "../../src/assets/icon/i-icon 1.svg";
import { useMapLocationUrl } from "../services/context/MapLocation";

function TripInput({
  prePickupLocation,
  preDestinationLocation,
  prePickUpDate,
  prePickUpTime,
  preDropDate,
  preDistance,
  preDuration
}) {

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  const [isLoading, startTransition] = useTransition();

  const [url, seturl] = useState("");
  const inputProps = {
    inputMode: "none",
  };

  const AutoComplete_southIndiaBounds = {
    south: 7.0, // southern of Tamil Nadu
    west: 76.5, // western of Karnataka
    north: 19.5, // northern of Telangana
    east: 83.5, // eastern of Andhra Pradesh
  };
  const AutoComplete_restrictions = {
    country: 'in'
  };
  const AutoComplete_options = {
    strictBounds: true,
  };

  const optionsDuration = ["Select Duration (Hrs)", "05", "06", "07", "08", "09", "10", "11", "12", "15"];
  const optionsDistance = ["Select Distance (Km)", "30", "40", "50", "60", "70", "80", "90", "100", "110", "120", "150"];

  const [pickupLocation, setPickupLocation] = useState("");
  const [pickupLocationError, setPickupLocationError] = useState("");
  const [destinationLocation, setDestinationLocation] = useState("");
  const [destinationLocationError, setDestinationLocationError] = useState("");
  const [pickUpTime, setPickUpTime] = useState(prePickUpTime);
  const [pickUpTimeError, setPickUpTimeError] = useState("");
  const [pickUpDate, setPickUpDate] = useState(prePickUpDate || DateUtils.getCurrentDate());
  const [pickUpDateFormatted, setPickUpDateFormatted] = useState("");
  const [pickUpDateError, setPickUpDateError] = useState("");
  const [dropDate, setDropDate] = useState(preDropDate || "");
  const [dropDateError, setDropDateError] = useState("");
  const [dropDateFormatted, setDropDateFormatted] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const [distance, setDistance] = useState(preDistance || "");
  const [distanceError, setDistanceError] = useState("");
  const [duration, setDuration] = useState(preDuration || "");
  const [durationError, setDurationError] = useState("");
  const [stopAnimation, setStopAnimation] = useState(true);

  // for getting location map-link
  const autocompletePickupRef = useRef(null);
  const autocompleteDropRef = useRef(null);

  const { setPickupLocationMapUrl, setDropLocationMapUrl } = useMapLocationUrl();

  const submitRef = useRef();

  const navigate = useNavigate();
  const urlOfPage = useLocation();

  dayjs.extend(customParseFormat);
  const currentTimePlusMinutes = dayjs().add(0, 'minutes'); // minutes ahead
  const currentDate = dayjs().startOf("day");

  useEffect(() => {
    if (dayjs(prePickUpTime, 'HH:mm').isBefore(currentTimePlusMinutes)) {
      if (DateUtils.convertDate(pickUpDate) === DateUtils.getCurrentDateFormatMonth()) {
        setPickUpTimeError(`Enter valid Pick-up time`);
      }
    }
    if (dayjs(pickUpDate).isBefore(currentDate)) {
      setPickUpDateError("Enter valid date");
    }
  }, []);

  useEffect(() => {
    seturl(urlOfPage.pathname);
    if (urlOfPage.pathname === "/local-trip") {
      setSelectedOption("localTrip");
    }
    if (urlOfPage.pathname === "/rental-trip") {
      setSelectedOption("rentalTrip");
    }
    if (urlOfPage.pathname === "/round-trip") {
      setSelectedOption("roundTrip");
    }
    if (urlOfPage.pathname === "/OneWay-Trip") {
      setSelectedOption("oneWay");
    }
    if (urlOfPage.pathname === "/") {
      setSelectedOption("oneWay");
    }// eslint-disable-next-line
  }, [urlOfPage]);

  const handleSearchCab = async () => {
    if (handleFormValidation()) {
      if (selectedOption === "rentalTrip") {
        navigate(`/rental-trip?pickup=${pickupLocation}&&pickupDate=${pickUpDateFormatted}&pickupTime=${pickUpTime}&distance=${distance}&duration=${duration}`);
      } else if (selectedOption === "localTrip") {
        navigate(`/local-trip?pickup=${pickupLocation}&destination=${destinationLocation}&pickupDate=${pickUpDateFormatted}&pickupTime=${pickUpTime}`);
      } else if (selectedOption === "roundTrip") {
        navigate(`/round-trip?pickup=${pickupLocation}&destination=${destinationLocation}&pickupDate=${pickUpDateFormatted}&pickupTime=${pickUpTime}&dropDate=${dropDateFormatted}`);
      } else {
        navigate(`/oneway-trip?pickup=${pickupLocation}&destination=${destinationLocation}&pickupDate=${pickUpDateFormatted}&pickupTime=${pickUpTime}`);
      }
    }
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    handlePickUpDateChange(pickUpDate);
  }, [pickUpDate]);

  useEffect(() => {
    handleDropDateChange(dropDate);
  }, [dropDate]);

  // useEffect(() => {
  //   const distanceDurationMapping = {
  //     "Select Distance (Km)": "Select Duration (Hrs)",
  //     "30": "03",
  //     "40": "04",
  //     "50": "05",
  //     "60": "06",
  //     "70": "07",
  //     "80": "08",
  //     "90": "09",
  //     "100": "10",
  //     "110": "11",
  //     "120": "12",
  //     "150": "15",
  //   };
  //   if (distance in distanceDurationMapping) {
  //     setDuration(distanceDurationMapping[distance]);
  //   }
  // }, [distance]);

  // useEffect(() => {
  //   const durationDistanceMapping = {
  //     "Select Duration (Hrs)": "Select Distance (Km)",
  //     "03": "30",
  //     "04": "40",
  //     "05": "50",
  //     "06": "60",
  //     "07": "70",
  //     "08": "80",
  //     "09": "90",
  //     "10": "100",
  //     "11": "110",
  //     "12": "120",
  //     "15": "150",
  //   };
  //   if (duration in durationDistanceMapping) {
  //     setDistance(durationDistanceMapping[duration]);
  //   }
  // }, [duration]);

  useEffect(() => {
    if (selectedOption !== "localTrip") {
      setStopAnimation(true);
      return;
    }
    const timeout = setTimeout(() => {
      setStopAnimation(false);
    }, 5000);
    return () => clearTimeout(timeout);
  }, [selectedOption]);

  const handlePickUpDateChange = (date) => {
    var event = new Date(date);
    let utcDate = event.toUTCString();
    let formattedEvent = new Date(utcDate);
    let formattedDate = formattedEvent.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
    const parsedDate = DateUtils.getExDateFormat(formattedDate);
    setPickUpDateFormatted(DateUtils.convertDateReverse(parsedDate));
    setPickUpDate(date);
    setDropDate("");
  };

  const handleDropDateChange = (date) => {
    var event = new Date(date);
    let utcDate = event.toUTCString();
    let formattedEvent = new Date(utcDate);
    let formattedDate = formattedEvent.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
    const parsedDate = DateUtils.getExDateFormat(formattedDate);
    setDropDateFormatted(DateUtils.convertDateReverse(parsedDate));
    setDropDate(date);
  };

  const handleFormValidation = () => {
    let formIsValid = true;

    if (!pickupLocation || typeof (pickupLocation) !== "string") {
      formIsValid = false;
      setPickupLocationError("Enter pick-up location");
    } else {
      setPickupLocationError("");
    }

    if (selectedOption !== "rentalTrip") {
      if (!destinationLocation || typeof (destinationLocation) !== "string") {
        formIsValid = false;
        setDestinationLocationError("Enter destination location");
      } else {
        setDestinationLocationError("");
      }
    }

    if (!pickUpDate) {
      formIsValid = false;
      setPickUpDateError("Enter pick-up date");
    } else if (dayjs(pickUpDate).isBefore(currentDate)) {
      formIsValid = false;
      setPickUpDateError("Invalid date");
    } else {
      setPickUpDateError("");
    }

    if (!pickUpTime) {
      formIsValid = false;
      setPickUpTimeError("Enter pick-up time");
    } else if (dayjs(pickUpTime, 'HH:mm').isBefore(currentTimePlusMinutes)) {
      if (DateUtils.convertDate(pickUpDateFormatted) === DateUtils.getCurrentDateFormatMonth()) {
        formIsValid = false;
        setPickUpTimeError(`Invalid time`);
      } else {
        setPickUpTimeError("");
      }
    } else {
      setPickUpTimeError("");
    }

    if (selectedOption === "roundTrip") {
      if (!dropDate) {
        formIsValid = false;
        setDropDateError("Enter drop date");
      } else if (dropDateFormatted < pickUpDateFormatted) {
        formIsValid = false;
        setDropDateError("Invalid date");
      } else {
        setDropDateError("");
      }
    }

    if (selectedOption === "rentalTrip") {
      if (!distance || distance === "Select Distance (Km)") {
        formIsValid = false;
        setDistanceError("Select a distance");
      } else {
        setDistanceError("");
      }
      if (!duration || duration === "Select Duration (Hrs)") {
        formIsValid = false;
        setDurationError("Select a duration");
      } else {
        setDurationError("");
      }
    }
    return formIsValid;
  };
  function onLoadForPickup(autocomplete) {
    autocompletePickupRef.current = autocomplete;
    if (prePickupLocation) setPickupLocation(prePickupLocation);
    else setPickupLocation(autocomplete);
  }
  const handlePickupLocationChange = () => {
    const autocomplete = autocompletePickupRef.current;
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place) {
        const { place_id, url } = place;
        // const mapUrl = `https://www.google.com/maps/place/?q=place_id:${place_id}`;
        console.log(url);
        setPickupLocationMapUrl(url);
      }
    }
  };
  function onLoadForDestination(autocomplete) {
    autocompleteDropRef.current = autocomplete;
    if (preDestinationLocation) setDestinationLocation(preDestinationLocation);
    else setDestinationLocation(autocomplete);
  }
  const handleDropLocationChange = () => {
    const autocomplete = autocompleteDropRef.current;
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place) {
        const { place_id, url } = place;
        // const mapUrl = `https://www.google.com/maps/place/?q=place_id:${place_id}`;
        setDropLocationMapUrl(url);
      }
    }
  };
  if (!isLoaded) {
    return <div className="bg-[#222831] py-8 my-10 lg:mx-10 md:mx-24 rounded-2xl">
      <div className="flex items-center justify-center w-full">
        {url === "/" && (
          <>
            <div className="flex items-center mb-5 bg-gray-300 rounded-3xl">
              <button
                className={`${selectedOption === "oneWay"
                  ? "bg-[#00ADB5] text-white"
                  : "bg-gray-300 text-gray-700"
                  } py-2 px-4 rounded-3xl md:w-30 text-sm`}
              >
                One Way
              </button>
              <button
                className={`${selectedOption === "roundTrip"
                  ? "bg-[#00ADB5] text-white"
                  : "bg-gray-300 text-gray-700"
                  } py-2 px-4 rounded-3xl md:w-30 text-sm`}
              >
                Round
              </button>
              <button
                className={`${selectedOption === "rentalTrip"
                  ? "bg-[#00ADB5] text-white"
                  : "bg-gray-300 text-gray-700"
                  } py-2 px-4 rounded-3xl md:w-30 text-sm`}
              >
                Rental
              </button>
              <button
                className={`${selectedOption === "localTrip"
                  ? "bg-[#00ADB5] text-white"
                  : "bg-gray-300 text-gray-700"
                  } py-2 px-4 rounded-3xl md:w-30 text-sm`}
              >
                Local
              </button>
            </div>
          </>
        )}
      </div>
      <div className="flex flex-col justify-center md:flex-row flex-wrap gap-x-1 gap-y-3 items-center md:items-start mb-3 ">
        <div className="relative my-2 md:my-0 animate-pulse bg-slate-100 rounded-md h-[40px]  w-[250px]">
        </div>
        <div className="relative animate-pulse bg-slate-100 rounded-md h-[40px]  w-[250px]">
        </div>
        <div className="relative my-2 md:my-0 animate-pulse bg-slate-100 rounded-md h-[40px]  w-[250px]">
        </div>
        <div class="relative animate-pulse bg-slate-100 rounded-md h-[40px]  w-[250px]">
        </div>
        {selectedOption === "roundTrip" && (
          <div className="relative my-2 md:my-0 animate-pulse bg-slate-100 rounded-md h-[40px]  w-[250px]">
          </div>
        )}
        <div className="my-2 mt-3 md:mt-0 md:my-0 animate-pulse bg-slate-100 rounded-md h-[40px]  w-[250px]">
        </div>
      </div>
    </div>
  }

  return (
    <div>
      <div className="bg-[#222831] py-8 my-10 lg:mx-10 md:mx-24 rounded-2xl">
        <div className="flex items-center justify-center w-full pb-4 md:pb-0 relative">
          {/* <div className="absolute flex flex-col justify-center items-center bottom-[4.6rem] md:bottom-14 text-[#00ADB5] font-medium -ml-40 md:-ml-52">
            <p className="text-white z-20 bg-[#222831]">OutStation</p>
            <div className="w-[140px] md:w-[180px] h-1 bg-[#00ADB5] -translate-y-3" />
          </div>
          <div className="absolute flex flex-col justify-center items-center top-10 md:top-9 text-[#00ADB5] font-medium ml-44 md:ml-56">
            <p className="text-white z-20 bg-[#222831]">Chennai</p>
            <div className="w-[130px] md:w-[180px] h-1 bg-[#00ADB5] -translate-y-3" />
          </div> */}
          {url === "/" && (
            <>
              <div className="flex items-center mb-5 bg-gray-300 rounded-3xl">
                <button
                  className={`${selectedOption === "oneWay"
                    ? "bg-[#00ADB5] text-white"
                    : "bg-gray-300 text-gray-700"
                    } py-2 md:py-1 px-2 md:px-3 rounded-md md:rounded-3xl md:w-30 text-sm flex flex-col items-center`}
                  onClick={() => handleOptionChange("oneWay")}
                >
                  <span className="flex gap-1 font-medium">One Way <span className="hidden md:block">Trip</span></span>
                  <span className={`text-[10px] font-semibold -mt-1 ${selectedOption === "oneWay" && "text-[#ffc300] md:text-[11px]"}`}>(Out Station)</span>
                </button>
                <button
                  className={`${selectedOption === "roundTrip"
                    ? "bg-[#00ADB5] text-white"
                    : "bg-gray-300 text-gray-700"
                    } py-2 md:py-1 px-3 md:px-4 rounded-md md:rounded-3xl md:w-30 text-sm flex flex-col items-center`}
                  onClick={() => handleOptionChange("roundTrip")}
                >
                  <span className="flex gap-1 font-medium">Round <span className="hidden md:block">Trip</span></span>
                  <span className={`text-[10px] font-semibold  -mt-1 ${selectedOption === "roundTrip" && "text-[#ffc300] md:text-[11px]"}`}>(Out Station)</span>
                </button>
                <button
                  className={`${selectedOption === "rentalTrip"
                    ? "bg-[#00ADB5] text-white"
                    : "bg-gray-300 text-gray-700"
                    } py-2 md:py-1 px-3 md:px-4 rounded-md md:rounded-3xl md:w-30 text-sm flex flex-col items-center`}
                  onClick={() => handleOptionChange("rentalTrip")}
                >
                  <span className="flex gap-1 font-medium">Rental <span className="hidden md:block">Trip</span></span>
                  <span className={`text-[10px] font-semibold  -mt-1 ${selectedOption === "rentalTrip" && "text-[#ffc300] md:text-[11px]"}`}>(Chennai)</span>
                </button>
                <button
                  className={`${selectedOption === "localTrip"
                    ? "bg-[#00ADB5] text-white"
                    : "bg-gray-300 text-gray-700"
                    } py-2 md:py-1 px-4 md:px-5 rounded-md md:rounded-3xl md:w-30 text-sm flex flex-col items-center`}
                  onClick={() => handleOptionChange("localTrip")}
                >
                  <span className="flex gap-1 font-medium">Local <span className="hidden md:block">Trip</span></span>
                  <span className={`text-[10px] font-semibold  -mt-1 ${selectedOption === "localTrip" && "text-[#ffc300] md:text-[11px]"}`}>(Chennai)</span>
                </button>
              </div>
            </>
          )}
        </div>
        <div className="flex flex-col justify-center md:flex-row flex-wrap gap-x-1 gap-y-3 items-center md:items-start md:mt-2">
          <div className="relative mb-2 md:mb-0">
            <Autocomplete onLoad={onLoadForPickup} onPlaceChanged={handlePickupLocationChange} restrictions={AutoComplete_restrictions} options={AutoComplete_options} bounds={AutoComplete_southIndiaBounds}>
              <input
                type="text"
                id="pickup"
                placeholder="Enter Pick Up Location"
                defaultValue={typeof (pickupLocation) === "string" ? pickupLocation : ""}
                className={`py-[10px]  h-[40px]  w-[250px] mr-1 text-[14px] bg-white  outline-none text-[#393E46] font-sans font-medium text-md  rounded-lg pl-10 ${selectedOption === "localTrip" && "pr-8"}`}
                onBlur={(e) => setPickupLocation(e.target.value)}
              />
            </Autocomplete>
            {pickupLocationError && (
              <p className="mt-1 text-sm text-red-500">
                {pickupLocationError}
              </p>
            )}
            <img src={taxiicon} alt="taxi-icon" className="custom-icon" />
            {selectedOption === "localTrip" && (
              <div className={`relative flex items-center group w-fit ${pickupLocationError ? "-mt-[54px]" : "-mt-[30px]"} left-[220px] mb-3`}>
                <img
                  className={`w-5 h-5 mx-[1px] ${stopAnimation && 'animate-pulse'}`}
                  src={iIcon}
                  alt="i"
                />
                <div className="absolute hidden group-hover:flex gap-1 md:group-hover:block bottom-8 -right-[13px] md:-right-[0px] whitespace-nowrap z-20 rounded-lg px-3 py-1 w-fit text-white font-semibold text-[10px] md:text-[12px] bg-[#00ADB5]">
                  <p>Availalable only within 70KM from</p> <p>Chennai, Tamil Nadu.</p>
                </div>
                <div className="absolute hidden group-hover:flex justify-center -right-1 bottom-[17px] rotate-180 rounded-lg px-2 py-1 h-auto text-[10px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#00ADB5"
                    class="bi bi-caret-up-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                  </svg>
                </div>
              </div>
            )}
          </div>
          {selectedOption !== "rentalTrip" && (
            <div className="relative mb-2 md:mb-0">
              <Autocomplete onLoad={onLoadForDestination} onPlaceChanged={handleDropLocationChange} restrictions={AutoComplete_restrictions} options={AutoComplete_options} bounds={AutoComplete_southIndiaBounds}>
                <input
                  type="text"
                  id="drop"
                  placeholder="Enter Destination"
                  defaultValue={typeof (destinationLocation) === "string" ? destinationLocation : ""}
                  className="py-[10px]  h-[40px]  w-[250px] mr-1 text-[14px] bg-white  outline-none   text-[#393E46] font-sans font-medium text-md  rounded-lg pl-10"
                  onBlur={(e) => setDestinationLocation(e.target.value)}
                />
              </Autocomplete>
              {destinationLocationError && (
                <p className="mt-1 text-sm text-red-500">
                  {destinationLocationError}
                </p>
              )}
              <img src={location} alt="location-icon" className="custom-icon" />
            </div>
          )}
          <div className="relative mb-2 md:my-0">
            <DatePicker
              className=" py-[10px]  h-[40px] mr-1 text-[14px]  outline-none text-[#393E46]  rounded-lg font-sans font-medium text-md w-[250px]   pl-10 "
              selected={pickUpDate ? new Date(pickUpDate) : null}
              onChange={handlePickUpDateChange}
              placeholderText="Enter Pick Up Date"
              dateFormat={"dd/MM/yyyy"}
              todayButton="Today"
              minDate={new Date(DateUtils.getDateYyyyMmDd())}
            />
            {pickUpDateError && (
              <p className="mt-1 text-sm text-red-500">{pickUpDateError}</p>
            )}
            <img src={calender} alt="calender-icon" className="custom-icon" />
          </div>
          <div class="relative">
            <TimeInput
              prePickUpTime={prePickUpTime || null}
              value={pickUpTime}
              setValue={setPickUpTime}
              placeholder={"Enter Pick Up Time"}
            />
            {pickUpTimeError && (
              <p className="mt-1 text-sm text-red-500">{pickUpTimeError}</p>
            )}
            <BiTimeFive className="text-2xl custom-icon" />
          </div>
          {selectedOption === "roundTrip" && (
            <div className="relative my-2 md:my-0">
              <DatePicker
                className=" py-[10px]  h-[40px] mr-1 text-[14px]  outline-none text-[#393E46]  rounded-lg font-sans font-medium text-md w-[250px]   pl-10 "
                selected={dropDate ? new Date(dropDate) : null}
                onChange={handleDropDateChange}
                placeholderText="Enter Drop Date"
                dateFormat="dd/MM/yyyy"
                preventOpenOnFocus={true}
                customInput={<input {...inputProps} />}
                minDate={pickUpDate ? new Date(pickUpDate) : new Date(DateUtils.getDateYyyyMmDd())}
              />
              {dropDateError && (
                <p className="mt-1 text-sm text-red-500">{dropDateError}</p>
              )}
              <img
                src={calender}
                alt="calender-icon"
                className="custom-icon" />
            </div>
          )}
          {selectedOption === "rentalTrip" && (
            <>
              <div className="relative my-2 md:my-0">
                <select
                  id="distance"
                  value={distance}
                  onChange={(e) => setDistance(e.target.value)}
                  className="py-[9px] h-[40px] w-[250px] mr-1 text-[14px] bg-white outline-none text-[#393E46] font-sans font-medium rounded-lg pl-9 cursor-pointer"
                >
                  {optionsDistance.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                {distanceError && (
                  <p className="mt-1 text-sm text-red-500">{distanceError}</p>
                )}
                {distance && distance !== "Select Distance (Km)" && (
                  <p
                    className={`absolute bottom-[9.5px] md:bottom-[9.5px] ${distance >= 100
                      ? "right-[10.2rem]"
                      : "right-[10.6rem]"
                      } text-[#393E46] font-sans font-medium text-sm ${distanceError && "mb-6"}`}
                  >
                    KM
                  </p>
                )}
                <img src={distanceIcon} alt="distance-icon" className="custom-icon-rental" />
              </div>
              <div className="relative mb-2 md:my-0">
                <select
                  id="duration"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                  className="py-[9px] pb-[10px] md:pb-3 h-[40px] w-[250px] mr-1 text-[14px] bg-white outline-none text-[#393E46] font-sans font-medium rounded-lg pl-9 cursor-pointer"
                >
                  {optionsDuration.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                {durationError && (
                  <p className="mt-1 text-sm text-red-500">{durationError}</p>
                )}
                {duration && duration !== "Select Duration (Hrs)" && (
                  <p
                    className={`absolute bottom-[10.4px] md:bottom-[9.9px] right-[9.7rem] text-[#393E46] font-sans font-medium text-sm ${durationError && "mb-6"}`}
                  >
                    Hours
                  </p>
                )}
                <img src={durationIcon} alt="duration-icon" className="custom-icon" />
              </div>
            </>
          )}
          <div className="my-2 mt-3 md:mt-0 md:my-0">
            <button
              onClick={() => startTransition(handleSearchCab)}
              ref={submitRef}
              className="bg-[#00ADB5] rounded-lg w-[250px] text-white rounded-r-lg px-6 py-[0.65rem] text-sm font-sans font-medium flex gap-1 justify-center"
            >
              Search Cab
              {isLoading && (
                <svg width="115px" height="115px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-2 h-4 w-4 animate-spin"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20.0001 12C20.0001 13.3811 19.6425 14.7386 18.9623 15.9405C18.282 17.1424 17.3022 18.1477 16.1182 18.8587C14.9341 19.5696 13.5862 19.9619 12.2056 19.9974C10.825 20.0328 9.45873 19.7103 8.23975 19.0612" stroke="#fff" stroke-width="0.744" stroke-linecap="round"></path></g></svg>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TripInput;
