import React, { useState } from "react";
import location from "../assets/images/location.png";
import email from "../assets/images/email.png";
import mobile from "../assets/images/smartphone.png";
import web from "../assets/images/web.png";
import qrcode from "../assets/images/qrcode.png";
import getiton from "../assets/images/getiton.png";
import linkedin from "../assets/images/linkedin.png";
import instagram from "../assets/images/instagram.png";
import facebook from "../assets/images/facebook.png";
import x from "../assets/images/x.png";
import arrow from "../assets/images/arrow.png";
import logo from "../assets/images/android-chrome-512x512.png";
import copyright from "../assets/images/copyright.png";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { Link } from "react-router-dom";
import { Modal } from "antd";
import gst from "../assets/images/gst.png";
import { IoMdCloseCircle } from "react-icons/io";

function Footer() {
  const [isQrModalOpen, setIsQrModalOpen] = useState(false);
  const [closeWhatsappPopup, setCloseWhatsappPopup] = useState(false);

  const Address = () => (
    <p className="font-sans text-sm font-normal text-white text-wrap max-w-[360px]">
      Plot No.11, Kandan Avenue, 24th Street 2nd Cross Street,
      JB Estate, Avadi, Chennai, Thiruvallur, Tamil Nadu, 600054
    </p>
  );
  const Email = () => (
    <a href="mailto:admin@chennaicabs.in" className="font-sans text-sm font-normal text-white">
      admin@chennaicabs.in
    </a>
  );
  const Mobile = () => (
    <a href="tel:+919841346080" className="font-sans text-sm font-normal text-white">
      +91 98413 46080
    </a>
  );
  const Website = () => (
    <a href="https://www.chennaicabs.in" className="font-sans text-sm font-normal text-white">
      www.chennaicabs.in
    </a>
  );
  const GST = () => (
    <span className="font-sans text-sm font-bold bg-gradient-to-r from-[#87f9ff] via-yellow-200 to-yellow-300 bg-clip-text text-transparent">
      GSTIN: 33EAYPS1903L2Z2
    </span>
  );

  return (
    <div className="bottom-0">
      <footer>
        <div className="hidden lg:block">
          <div className="Container bg-[#00ADB5] flex justify-between py-5 w-full px-10">
            <div className="text-justify ">
              <div className="mb-2">
                <span className="text-[#222831] text-lg font-bold font-sans">
                  About Us
                </span>
              </div>
              <div>
                <p className="text-sm pr-14 text-white font-normal font-sans w-[450px]">
                  Chennai Cabs is established on November, 2016. We are the
                  leading cab service provider in Tamil Nadu, Andra Pradesh,
                  Karnataka and Telangana, committed to providing safe,
                  reliable, and affordable transportation solutions for our
                  customers. We strive to provide the best possible customer
                  experience and are always available to assist with any queries
                  or concerns.
                </p>
              </div>
            </div>
            <div>
              <div className="mb-2">
                <span className="text-[#222831] text-lg font-bold font-sans">
                  Contact Info
                </span>
              </div>
              <div className="flex mb-2">
                <div className="pt-2 mr-2">
                  <img
                    className="w-[16px] h-[16px]"
                    src={location}
                    alt="location-icon"
                  />
                </div>
                <div>
                  <Address />
                </div>
              </div>
              <div className="flex mb-2">
                <div className="pt-1.5 mr-2">
                  <img
                    className="w-[16px] h-[16px]"
                    src={gst}
                    alt="gst-icon"
                  />
                </div>
                <div>
                  <GST />
                </div>
              </div>
              <div className="flex mb-2">
                <div className="pt-2 mr-2">
                  <img
                    className="w-[16px] h-[16px]"
                    src={email}
                    alt="email-icon"
                  />
                </div>
                <div>
                  <Email />
                </div>
              </div>
              <div className="flex mb-2">
                <div className="pt-2 mr-2">
                  <img
                    className="w-[16px] h-[16px]"
                    src={mobile}
                    alt="mobile-icon"
                  />
                </div>
                <div>
                  <Mobile />
                </div>
              </div>
              <div className="flex">
                <div className="pt-2 mr-2">
                  <img className="w-[16px] h-[16px]" src={web} alt="web-icon" />
                </div>
                <div>
                  <Website />
                </div>
              </div>
            </div>

            <div>
              <div className="mb-2">
                <span className="text-[#222831] text-lg font-bold font-sans">
                  Our Services
                </span>
              </div>
              <div>
                <ul>
                  <li className="flex items-center mb-2 font-sans text-sm font-medium text-white">
                    {" "}
                    <img src={arrow} alt="arrow-img" className="w-3 h-3" />{" "}
                    <span className="ml-1">Local Rentals</span>
                  </li>
                  <li className="flex items-center mb-2 font-sans text-sm font-medium text-white">
                    <img src={arrow} alt="arrow-img" className="w-3 h-3" />{" "}
                    <span className="ml-1">One Way Drop</span>
                  </li>
                  <li className="flex items-center mb-2 font-sans text-sm font-medium text-white">
                    <img src={arrow} alt="arrow-img" className="w-3 h-3" />{" "}
                    <span className="ml-1">Round Trip Cabs</span>
                  </li>
                  <li className="flex items-center mb-2 font-sans text-sm font-medium text-white">
                    <img src={arrow} alt="arrow-img" className="w-3 h-3" />{" "}
                    <span className="ml-1">Corporate Fleet</span>
                  </li>
                  <li className="flex items-center font-sans text-sm font-medium text-white">
                    <img src={arrow} alt="arrow-img" className="w-3 h-3" />{" "}
                    <span className="ml-1">Wedding Packages</span>
                  </li>
                </ul>
              </div>
            </div>

            <div>
              <div className="flex justify-center">
                <img className="w-[80px] h-[80px] cursor-pointer" src={qrcode} alt="qr-icon" onClick={() => setIsQrModalOpen(true)} />
              </div>
              <div className="flex justify-center mt-4">
                <a href="https://play.google.com/store/apps/details?id=com.cabs.chennaicabs">
                  <img
                    className="w-[100px] h-[30px]"
                    src={getiton}
                    alt="getiton-icon"
                  />
                </a>
              </div>
              <div className="flex justify-center mt-4">
                <div className="mr-1">
                  <a
                    href="https://in.linkedin.com/in/chennai-cabs-b08575272"
                    target="_"
                  >
                    <img
                      className="w-[25px] h-[25px]"
                      src={linkedin}
                      alt="linkedin-icon"
                    />
                  </a>
                </div>
                <div className="mr-1">
                  <a href="https://www.instagram.com/chennaicabs/" target="_">
                    <img
                      className="w-[25px] h-[25px]"
                      src={instagram}
                      alt="instagram-icon"
                    />
                  </a>
                </div>
                <div className="mr-1">
                  <a
                    href="https://m.facebook.com/people/Chennai-Cabs/100063900039604/"
                    target="_"
                  >
                    <img
                      className="w-[25px] h-[25px]"
                      src={facebook}
                      alt="facebook-icon"
                    />
                  </a>
                </div>
                <div className="bg-white rounded-md">
                  <a
                    href="https://twitter.com/chennaicabs_"
                    target="_"
                  >
                    <img
                      className="w-[25px] h-[25px]"
                      src={x}
                      alt="x-icon"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#222831] w-full flex justify-around py-2">
            <Link
              to={"/TermsAndConditions"}
              className="pl-1 font-sans text-sm font-normal text-white"
            >
              Terms and Conditions
            </Link>
            <Link
              to={"/PrivacyPolicy"}
              className="pl-1 font-sans text-sm font-normal text-white"
            >
              Privacy Policy
            </Link>
            <div>
              <div className=" flex items-center">
                <img
                  className="w-[14px] h-[14px]"
                  src={copyright}
                  alt="copyright-icon"
                />
                <span className="pl-1 font-sans text-sm font-normal text-white">
                  2024 CHENNAI CABS. All Rights Reserved. Developed by <Link to={"https://www.techhivetechnology.com"} target="_blank" className="hover:text-[#00ADB5]">TechHive Technology</Link>
                </span>
              </div>
            </div>
            <Link
              to={"/RefundPolicy"}
              className="pl-1 font-sans text-sm font-normal text-white"
            >
              Refund Policy | Cancellation Policy
            </Link>
          </div>
        </div>
        <div className="block lg:hidden">
          <div className="Container bg-[#17949a] flex justify-between py-5 flex-wrap    pl-4 sm:px-5 lg:px-10">
            <div className="text-justify ">
              <div className="mb-2">
                <span className="text-[#0b0e11] pl-2 text-lg font-bold font-sans">
                  About Us
                </span>
              </div>
              <div className="flex mb-3">
                <p className="flex flex-wrap items-center justify-center px-2 pr-4 font-sans text-sm font-normal text-white lg:text-lg">
                  Chennai Cabs is established on November, 2016. We are the
                  leading cab service provider in Tamil Nadu, Andra Pradesh,
                  Karnataka and Telangana, committed to providing safe,
                  reliable, and affordable transportation solutions for our
                  customers. We strive to provide the best possible customer
                  experience and are always available to assist with any queries
                  or concerns.
                </p>
              </div>
            </div>
            <div className="mt-3 mr-10 ">
              <div className="mb-1">
                <span className="text-[#0b0e11] pl-2 text-lg font-bold font-sans">
                  Contact Info
                </span>
              </div>
              <div className="flex mb-2">
                <div className="pt-2 mr-2">
                  <img
                    className="w-[16px] h-[16px]"
                    src={location}
                    alt="location-icon"
                  />
                </div>
                <div>
                  <Address />
                </div>
              </div>
              <div className="flex mb-2">
                <div className="pt-1.5 mr-2">
                  <img
                    className="w-[16px] h-[16px]"
                    src={gst}
                    alt="gst-icon"
                  />
                </div>
                <div>
                  <GST />
                </div>
              </div>
              <div className="flex mb-2">
                <div className="pt-2 mr-2">
                  <img
                    className="w-[16px] h-[16px]"
                    src={email}
                    alt="email-icon"
                  />
                </div>
                <div>
                  <Email />
                </div>
              </div>
              <div className="flex mb-2">
                <div className="pt-2 mr-2">
                  <img
                    className="w-[16px] h-[16px]"
                    src={mobile}
                    alt="mobile-icon"
                  />
                </div>
                <div>
                  <Mobile />
                </div>
              </div>
              <div className="flex">
                <div className="pt-2 mr-2">
                  <img className="w-[16px] h-[16px]" src={web} alt="web-icon" />
                </div>
                <div>
                  <Website />
                </div>
              </div>
            </div>

            <div className="mt-4">
              <div className="mb-1">
                <span className="text-[#0b0e11] pl-2  text-lg font-bold font-sans">
                  Our Services
                </span>
              </div>
              <div>
                <ul>
                  <li className="flex items-center mb-2 font-sans text-sm font-medium text-white">
                    {" "}
                    <img src={arrow} alt="arrow-img" className="w-3 h-3" />{" "}
                    <span className="ml-1">Local Rentals</span>
                  </li>
                  <li className="flex items-center mb-2 font-sans text-sm font-medium text-white">
                    <img src={arrow} alt="arrow-img" className="w-3 h-3" />{" "}
                    <span className="ml-1">One Way Drop</span>
                  </li>
                  <li className="flex items-center mb-2 font-sans text-sm font-medium text-white">
                    <img src={arrow} alt="arrow-img" className="w-3 h-3" />{" "}
                    <span className="ml-1">Round Trip Cabs</span>
                  </li>
                  <li className="flex items-center mb-2 font-sans text-sm font-medium text-white">
                    <img src={arrow} alt="arrow-img" className="w-3 h-3" />{" "}
                    <span className="ml-1">Corporate Fleet</span>
                  </li>
                  <li className="flex items-center font-sans text-sm font-medium text-white">
                    <img src={arrow} alt="arrow-img" className="w-3 h-3" />{" "}
                    <span className="ml-1">Wedding Packages</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="mr-2">
              <div className="flex items-center justify-center mt-4">
                <img className="w-[80px] h-[80px]" src={qrcode} alt="qr-icon" onClick={() => setIsQrModalOpen(true)} />
              </div>
              <div className="flex items-center justify-center mt-4">
                <a href="https://play.google.com/store/apps/details?id=com.cabs.chennaicabs">
                  <img
                    className="w-[100px] h-[30px]"
                    src={getiton}
                    alt="getiton-icon"
                  />
                </a>
              </div>
              <div className="flex items-center justify-center mt-4">
                <div className="mr-1">
                  <a
                    href="https://in.linkedin.com/in/chennai-cabs-b08575272"
                    target="_"
                  >
                    <img
                      className="w-[25px] h-[25px]"
                      src={linkedin}
                      alt="linkedin-icon"
                    />
                  </a>
                </div>
                <div className="mr-1">
                  <a href="https://www.instagram.com/chennaicabs/" target="_">
                    <img
                      className="w-[25px] h-[25px]"
                      src={instagram}
                      alt="instagram-icon"
                    />
                  </a>
                </div>
                <div className="mr-1">
                  <a
                    href="https://m.facebook.com/people/Chennai-Cabs/100063900039604/"
                    target="_"
                  >
                    <img
                      className="w-[25px] h-[25px]"
                      src={facebook}
                      alt="facebook-icon"
                    />
                  </a>
                </div>
                <div className="bg-white rounded-md">
                  <a
                    href="https://twitter.com/chennaicabs_"
                    target="_"
                  >
                    <img
                      className="w-[25px] h-[25px]"
                      src={x}
                      alt="x-icon"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#222831] w-full flex flex-wrap items-center justify-center py-2">
            <div className="flex items-center">
              <img
                className="w-[14px] h-[14px]"
                src={copyright}
                alt="copyright-icon"
              />
              <span className="pl-1 font-sans text-sm font-normal text-white">
                2023 All Rights Reserved.
              </span>
            </div>
            <span className="pl-1 font-sans text-sm font-normal text-white">
              Developed by  <Link to={"https://www.techhivetechnology.com"} target="_blank" className="text-[#00ADB5]">TechHive Technology</Link>
            </span>
          </div>
        </div>
        <div className="whatsapp top-[33.5rem] -right-[78.5rem] h-full z-10 fixed ">
          {closeWhatsappPopup === false && (
            <>
              <FloatingWhatsApp
                showPopup={false}
                phoneNumber="+916382452160"
                accountName="Chennai Cabs"
                statusMessage="Available"
                // avatar="https://chennaicabs.in/static/media/logo.9c126ae86a0f9905d02d.png"
                avatar={logo}
              />
            </>
          )}
        </div>
        {closeWhatsappPopup === false && (<button className="text-xl bg-white rounded-full font-semibold fixed z-10 bottom-20 right-[0.6rem]" onClick={() => { setCloseWhatsappPopup(true) }}><IoMdCloseCircle />
        </button>)}
      </footer>
      <Modal
        centered
        open={isQrModalOpen}
        onCancel={() => setIsQrModalOpen(!isQrModalOpen)}
        footer={false}
        className="my-10"
        width="300px"
      >
        <img className="pt-10" src={qrcode} alt="qr-icon" />
      </Modal>
    </div >
  );
}

export default Footer;
