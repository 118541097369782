import React from "react";
import Layout from "../layouts/PageLayout";
import localrental from "../assets/images/localRental2.jpg";
import onewaydrop from "../assets/images/onewaydrop.jpg";
import roundtripcab from "../assets/images/round-trip-cab.jpg";

function Ourservices() {
  return (
    <Layout className="w-screen">
      <div class="lg:hidden">
        {" "}
        <div className="px-6">
          <div className="my-7 text-center">
            <span className="text-[#00ADB5] text-2xl font-bold font-sans">
              OUR SERVICES
            </span>
            <div className="w-[160px] h-[4px] bg-[#00ADB5] mx-auto "></div>
          </div>
          <div>
            <p className="mb-5 indent-14 text-base font-medium font-sans text-justify text-[#393E46]">
              We offer a wide range of transportation services to meet your
              needs, including airport transfers, corporate transportation,
              event transportation, and personal transportation. Our services
              are available 24/7, and you can book your ride online or over the
              phone. We also offer flexible payment options and transparent
              pricing to make your ride hassle-free.
            </p>
          </div>

          <div className="flex flex-wrap items-center w-full my-10">
            <div className="w-full">
              <div className="mb-2">
                <span className="text-[#00ADB5] text-2xl font-bold font-sans">
                  LOCAL RENTAL
                </span>
                <div className="w-[170px] h-[4px] bg-[#00ADB5] "></div>
              </div>
            </div>
            <div className="w-full mt-4">
              <img
                className="w-[400px] h-[250px] border-4 rounded-lg border-[#222831] mx-auto my-auto"
                src={localrental}
                alt="localrental-icon"
              />
              <div>
                <p className="text-base mt-2 font-medium text-justify text-[#393E46]">
                  Welcome to our local car rental company, where we provide
                  high-quality cab services for all your transportation needs.
                  Whether you're travelling for business or leisure, our fleet
                  of well-maintained vehicles and professional drivers will get
                  you where you need to go safely and comfortably. Our easy
                  online booking system and competitive pricing make it simple
                  to reserve your ride, and our flexible pickup and drop-off
                  options ensure that you can travel on your own schedule.
                  Contact us today to experience the convenience and reliability
                  of our local cab service.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap items-center w-full my-10">
            <div className="w-full">
              <div className="flex justify-end">
                <span className="text-[#00ADB5] text-2xl font-bold font-sans ">
                  ONE WAY DROP
                </span>
              </div>
              <div className="flex justify-end w-full">
                <div className="w-[180px] mb-2 h-[4px] bg-[#00ADB5]  "></div>
              </div>
            </div>
            <div className="w-full">
              <img
                className="w-[400px] h-[250px] border-4 rounded-lg border-[#222831] mx-auto my-auto"
                src={onewaydrop}
                alt="onewaydrop-icon"
              />
              <div>
                <p className="text-base mt-2 font-medium text-justify text-[#393E46]">
                  Welcome to our One Way Drop Service, with our vast network of
                  over 15 lakhs of one-way routes, we ensure that you can travel
                  to any corner of the country with us. Our rock-bottom rates
                  require you to pay only for one side, making our service
                  affordable for all. Whether you have an important destination
                  to reach or simply want to explore, we guarantee to provide
                  you with safe and reliable transportation to get you there.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap items-center w-full my-10">
            <div className="w-full">
              <div className="mb-2">
                <span className="text-[#00ADB5] text-2xl font-bold font-sans">
                  ROUND TRIP CABS
                </span>
                <div className="flex justify-start w-full">
                  <div className="w-[220px] mb-2 h-[4px] bg-[#00ADB5]  "></div>
                </div>
              </div>
            </div>
            <div className="w-full">
              <img
                className="w-[400px] h-[250px] border-4 rounded-lg border-[#222831] mx-auto my-auto"
                src={roundtripcab}
                alt="roundtripcab-icon"
              />
              <div>
                <p className="text-base mt-2 font-medium text-justify text-[#393E46]">
                  Welcome to our round-trip cab service, where we provide
                  reliable transportation solutions for your round-trip travel
                  needs. Whether you're travelling for business or pleasure, our
                  professional drivers will ensure that you have a safe and
                  comfortable journey. With our modern fleet of vehicles and
                  extensive network, we offer flexible round-trip options for
                  local and outstation travel. Our easy online booking system
                  and transparent pricing ensure a hassle-free booking
                  experience, and our 24/7 customer support is always available
                  to assist you. Contact us today and experience the convenience
                  and comfort of our round-trip cab service.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap items-center w-full my-10">
            <div className="w-full">
              <div className="flex justify-end">
                <span className="text-[#00ADB5] text-2xl font-bold font-sans ">
                  CORPORATE FLEET
                </span>
              </div>
              <div className="flex justify-end w-full">
                <div className="w-[210px] mb-2 h-[4px] bg-[#00ADB5]  "></div>
              </div>
            </div>
            <div className="w-full">
              <img
                className="w-[400px] h-[250px] border-4 rounded-lg border-[#222831] mx-auto my-auto"
                src={onewaydrop}
                alt="corporatefleet-icon"
              />
              <div>
                <p className="text-base mt-2 font-medium text-justify text-[#393E46]">
                  Welcome to our corporate fleet service, where we provide
                  customized transportation solutions for your business needs.
                  We understand the importance of timely and efficient
                  transportation for your executives, clients, and employees.
                  Our fleet of well-maintained vehicles and professional drivers
                  ensures a comfortable and safe journey. With our flexible
                  booking options and transparent pricing, we offer a
                  hassle-free and cost-effective solution for your corporate
                  transportation requirements. Our dedicated account managers
                  and 24/7 customer support team provide personalized assistance
                  and ensure that your transportation needs are met efficiently.
                  Contact us today to experience the convenience and reliability
                  of our corporate fleet service.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap items-center w-full my-10">
            <div className="w-full">
              <div className="mb-2">
                <span className="text-[#00ADB5] text-2xl font-bold font-sans">
                  WEDDING PACKAGE
                </span>
                <div className="w-[230px] h-[4px] bg-[#00ADB5]"></div>
              </div>
            </div>
            <div className="w-full">
              <img
                className="w-[400px] h-[250px] border-4 rounded-lg border-[#222831] mx-auto my-auto"
                src={onewaydrop}
                alt="weddingcab-icon"
              />
              <div>
                <p className="text-base mt-2 font-medium text-justify text-[#393E46]">
                  Our marriage car cab service provides you with a luxurious and
                  comfortable ride for your special day. Our fleet of elegant
                  cars includes a variety of options to suit your preferences,
                  from classic vintage models to modern luxury cars. Our
                  professional and courteous drivers will ensure that you arrive
                  at your wedding venue in style and on time. We understand the
                  importance of your big day, which is why we go above and
                  beyond to provide you with a seamless and stress-free
                  transportation experience. Let us take care of the
                  transportation, so you can focus on enjoying your wedding day
                  with your loved ones. Book our marriage car cab service today
                  and make your wedding day even more memorable.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hidden lg:block">
        <div className="px-10">
          <div className="my-10 text-center">
            <span className="text-[#00ADB5] text-2xl font-bold font-sans">
              OUR SERVICES
            </span>
            <div className="w-[160px] h-[4px] bg-[#00ADB5] mx-auto "></div>
          </div>
          <div>
            <p className="mb-5 indent-14 text-base font-medium font-sans text-justify text-[#393E46]">
              We offer a wide range of transportation services to meet your
              needs, including airport transfers, corporate transportation,
              event transportation, and personal transportation. Our services
              are available 24/7, and you can book your ride online or over the
              phone. We also offer flexible payment options and transparent
              pricing to make your ride hassle-free.
            </p>
          </div>

          <div className="flex items-center w-full my-10">
            <div className="w-4/12">
              <img
                className="w-[400px] h-[250px] border-4 rounded-lg border-[#222831] mx-auto my-auto"
                src={localrental}
                alt="localrental-icon"
              />
            </div>
            <div className="w-8/12">
              <div className="mb-2">
                <span className="text-[#00ADB5] text-2xl font-bold font-sans">
                  LOCAL RENTAL
                </span>
                <div className="flex justify-start w-full">
                  <div className="w-[170px] mb-2 h-[4px] bg-[#00ADB5]  "></div>
                </div>
              </div>
              <div>
                <p className="text-base font-medium text-justify text-[#393E46]">
                  Welcome to our local car rental company, where we provide
                  high-quality cab services for all your transportation needs.
                  Whether you're travelling for business or leisure, our fleet
                  of well-maintained vehicles and professional drivers will get
                  you where you need to go safely and comfortably. Our easy
                  online booking system and competitive pricing make it simple
                  to reserve your ride, and our flexible pickup and drop-off
                  options ensure that you can travel on your own schedule.
                  Contact us today to experience the convenience and reliability
                  of our local cab service.
                </p>
              </div>
            </div>
          </div>

          <div className="flex items-center w-full my-10">
            <div className="w-8/12">
              <div className="flex justify-end ">
                <span className="text-[#00ADB5] text-2xl font-bold font-sans ">
                  ONE WAY DROP
                </span>
              </div>
              <div className="flex justify-end w-full">
                <div className="w-[180px] mb-2 h-[4px] bg-[#00ADB5]  "></div>
              </div>
              <div>
                <p className="text-base font-medium text-justify text-[#393E46]">
                  Welcome to our One Way Drop Service, with our vast network of
                  over 15 lakhs of one-way routes, we ensure that you can travel
                  to any corner of the country with us. Our rock-bottom rates
                  require you to pay only for one side, making our service
                  affordable for all. Whether you have an important destination
                  to reach or simply want to explore, we guarantee to provide
                  you with safe and reliable transportation to get you there.
                </p>
              </div>
            </div>
            <div className="w-4/12">
              <img
                className="w-[400px] h-[250px] border-4 rounded-lg border-[#222831] mx-auto my-auto"
                src={onewaydrop}
                alt="onewaydrop-icon"
              />
            </div>
          </div>

          <div className="flex items-center w-full my-10">
            <div className="w-4/12">
              <img
                className="w-[400px] h-[250px] border-4 rounded-lg border-[#222831] mx-auto my-auto"
                src={roundtripcab}
                alt="roundtripcab-icon"
              />
            </div>
            <div className="w-8/12">
              <div className="mb-2">
                <span className="text-[#00ADB5] text-2xl font-bold font-sans">
                  ROUND TRIP CABS
                </span>
                <div className="flex justify-start w-full">
                  <div className="w-[215px] mb-2 h-[4px] bg-[#00ADB5]  "></div>
                </div>
              </div>
              <div>
                <p className="text-base font-medium text-justify text-[#393E46]">
                  Welcome to our round-trip cab service, where we provide
                  reliable transportation solutions for your round-trip travel
                  needs. Whether you're travelling for business or pleasure, our
                  professional drivers will ensure that you have a safe and
                  comfortable journey. With our modern fleet of vehicles and
                  extensive network, we offer flexible round-trip options for
                  local and outstation travel. Our easy online booking system
                  and transparent pricing ensure a hassle-free booking
                  experience, and our 24/7 customer support is always available
                  to assist you. Contact us today and experience the convenience
                  and comfort of our round-trip cab service.
                </p>
              </div>
            </div>
          </div>

          <div className="flex items-center w-full my-10">
            <div className="w-8/12">
              <div className="flex justify-end">
                <span className="text-[#00ADB5] text-2xl font-bold font-sans ">
                  CORPORATE FLEET
                </span>

              </div>
              <div className="flex justify-end w-full">
                <div className="w-[210px] mb-2 h-[4px] bg-[#00ADB5]  "></div>
              </div>
              <div>
                <p className="text-base font-medium text-justify text-[#393E46]">
                  Welcome to our corporate fleet service, where we provide
                  customized transportation solutions for your business needs.
                  We understand the importance of timely and efficient
                  transportation for your executives, clients, and employees.
                  Our fleet of well-maintained vehicles and professional drivers
                  ensures a comfortable and safe journey. With our flexible
                  booking options and transparent pricing, we offer a
                  hassle-free and cost-effective solution for your corporate
                  transportation requirements. Our dedicated account managers
                  and 24/7 customer support team provide personalized assistance
                  and ensure that your transportation needs are met efficiently.
                  Contact us today to experience the convenience and reliability
                  of our corporate fleet service.
                </p>
              </div>
            </div>
            <div className="w-4/12">
              <img
                className="w-[400px] h-[250px] border-4 rounded-lg border-[#222831] mx-auto my-auto"
                src={onewaydrop}
                alt="corporatefleet-icon"
              />
            </div>
          </div>

          <div className="flex items-center w-full my-10">
            <div className="w-4/12">
              <img
                className="w-[400px] h-[250px] border-4 rounded-lg border-[#222831] mx-auto my-auto"
                src={onewaydrop}
                alt="weddingcab-icon"
              />
            </div>
            <div className="w-8/12">
              <div className="">
                <span className="text-[#00ADB5] text-2xl font-bold font-sans">
                  WEDDING PACKAGE
                </span>
              </div>
              <div className="flex justify-start w-full">
                <div className="w-[230px] mb-2 h-[4px] bg-[#00ADB5]  "></div>
              </div>
              <div>
                <p className="text-base font-medium text-justify text-[#393E46]">
                  Our marriage car cab service provides you with a luxurious and
                  comfortable ride for your special day. Our fleet of elegant
                  cars includes a variety of options to suit your preferences,
                  from classic vintage models to modern luxury cars. Our
                  professional and courteous drivers will ensure that you arrive
                  at your wedding venue in style and on time. We understand the
                  importance of your big day, which is why we go above and
                  beyond to provide you with a seamless and stress-free
                  transportation experience. Let us take care of the
                  transportation, so you can focus on enjoying your wedding day
                  with your loved ones. Book our marriage car cab service today
                  and make your wedding day even more memorable.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Ourservices;
