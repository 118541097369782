import { Layout } from "antd";
import React, { useEffect } from "react";
import Header from "../layouts/Navbar";
import Footer from "../layouts/Footer";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <Header />
      <div className="container mt-5 mx-auto px-4 pb-10">
        <h1 className="text-3xl font-bold my-4 text-red-500">Privacy Policy</h1>

        <section>
          <h2 className="text-lg font-semibold  mt-6 mb-[6px]">Introduction</h2>
          <p>
            This privacy statement explains the privacy practices of Chennai
            Cabs for users of the Chennai Cabs app and websites, including any
            other services offered by Chennai Cabs (collectively, the “Site”).
          </p>
        </section>

        <section>
          <h2 className="text-lg font-semibold mt-6 mb-[6px]">
            Chennai Cabs' Commitment to Privacy
          </h2>
          <p>
            Chennai Cabs respects your right to privacy. Your ability to make
            informed choices about the uses of your information is important to
            us. This privacy statement explains Chennai Cabs' policy regarding
            the collection, use, disclosure, and protection of Personal
            Information. The terms of this privacy statement apply to
            information collected from you unless different terms are specified
            as part of a special offer or in another form or contract we provide
            you.
          </p>
        </section>

        <section>
          <h2 className="text-lg font-semibold mt-6 mb-[6px]">
            Account Information
          </h2>
          <p>
            Except as otherwise set forth herein, we will keep confidential any
            Private Information related to your account with us.
          </p>
        </section>

        <section>
          <h2 className="text-lg font-semibold mt-6 mb-[6px]">
            What is Personal Information?
          </h2>
          <p>
            Personal Information is any information that can be directly
            associated with an identifiable natural person such as a customer’s
            name, telephone number, email address.
          </p>
        </section>

        <section>
          <h2 className="text-lg font-semibold mt-6 mb-[6px]">
            Collection of Personal Information
          </h2>
          <p>
            We collect Personal Information that you provide us, such as when
            you:
            <li>Register to use the Chennai Cabs App </li>
            <li>
              Place an order or purchase products or services online on the
              Chennai Cabs Website{" "}
            </li>
            <li>
              Contact our customer support whether by phone, email, or chat.
            </li>
          </p>
        </section>
        <section>
          <h2 className="text-lg font-semibold mt-6 mb-[6px]">
            Use of Personal Information
          </h2>
          <p>
            The Personal Information we collect is used to process your requests
            or transactions, to provide you high-quality service, to customize
            the Site according to your preferences, and to tell you about
            specific goods or services we believe will be of interest to you. If
            you prefer not to receive promotional information from us, we make
            it easy for you to let us know. You can contact us at any time to
            decline promotional information.
          </p>
        </section>
        <section>
          <h2 className="text-lg font-semibold mt-6 mb-[6px]">
            Sharing of Personal Information
          </h2>
          <p>
            Chennai Cabs does not sell or rent Personal Information to third
            parties. Chennai Cabs does not share or disclose Personal
            Information to third parties without your prior consent, except in
            the limited circumstances described below under the heading
            “Disclosures”.
          </p>
        </section>
        <section>
          <h2 className="text-lg font-semibold mt-6 mb-[6px]">Disclosures</h2>
          <p>
            We may disclose Personal Information in the good faith belief that
            we are required to do so by law, or that doing so is reasonably
            necessary to comply with legal process or contractual necessity,
            respond to any claims, or to protect the rights, property, or
            personal safety of Chennai Cabs, our customers, or the public.
            Information about our customers, including Personal Information, may
            be disclosed if it is necessary for legitimate purposes, for example
            as part of, or during negotiations of, any merger, sale of company
            assets, or acquisition. Other than in circumstances enumerated
            above, we have a policy of obtaining a person’s consent if we intend
            to process his/her Personal Information.
          </p>
        </section>
        <section>
          <h2 className="text-lg font-semibold mt-6 mb-[6px]">
            Protection of Personal Information
          </h2>
          <p>
            <li>
              Chennai Cabs has put into place security measures in an effort to
              protect Personal Information from loss, misuse, or alteration
              while it is under our control. Personal Information we collect is
              stored electronically. We use technical, contractual,
              administrative, and physical measures in an effort to protect
              against unauthorized access.
            </li>
          </p>
          <p>
            <li>
              Account information is accessible online only through the use of a
              password. To protect the confidentiality of Personal Information,
              you must keep your password confidential and not disclose it to
              any other person. If other people have access to your email, they
              may be able to obtain access to your password and obtain Personal
              Information about you or change information about your user
              profile.
            </li>
          </p>
          <p>
            <li>
              While we use reasonable measures to protect information that is
              stored within our database, and we restrict access to information
              to those employees who need access to perform their job functions,
              such as our customer service personnel and technical staff, we
              cannot guarantee the security of account information. Unauthorized
              entry or use, hardware or software failure, and other factors may
              compromise the security of account information at any time. We
              cannot ensure the security of the information you transmit to us,
              and so we urge you to take every precaution to protect your
              Personal Information when you are on the Internet. Change your
              passwords often, use a combination of letters and numbers, and
              make sure you use a secure browser.
            </li>
          </p>
        </section>
        <section>
          <h2 className="text-lg font-semibold mt-6 mb-[6px]">Choice</h2>
          <p>
            By registering for any part of the Site or any services or news
            provided by us, you agree to receive promotional messages from
            Chennai Cabs. If at any time you wish to stop receiving promotional
            information from Chennai Cabs, you may opt out by doing one of the
            following:
            <li>
              Calling our service representatives at the phone numbers provided
              at Contact Us
            </li>
            <li>Emailing us at admin@chennaicabs.in</li>
            <li> Clicking on the “unsubscribe” link on our newsletters</li>
          </p>
        </section>
        <section>
          <h2 className="text-lg font-semibold mt-6 mb-[6px]">
            Aggregate Information
          </h2>
          <p>
            Personal information does not include “aggregate” information.
            Aggregate information is data we collect about a group or category
            of products, services or customers, from which individual customer
            identities have been removed and a specific person cannot be
            identified through such information. Aggregate information is also
            any information or data that we collect from Chennai Cabs Devices
            which are installed with you. In other words, information about how
            you use a service may be collected and combined with information
            about how others use the same service, but no Personal Information
            will be included in the resulting data. Likewise, information about
            the products or services you use or purchase may be collected and
            combined with information about the products and services used or
            purchased by others, but no Personal Information will be included in
            the resulting data. Among others, aggregate data helps understand
            trends and customer needs so that new products and services can be
            considered and so existing products and services can be tailored to
            customer desires.
          </p>
          <p>
            Chennai Cabs appreciates the importance of safeguarding the privacy
            of your Personal Information that may be provided to us. Chennai
            Cabs does use and disclose de-identified aggregate information, for
            the purposes of evaluating and improving our services and tailoring
            our products and for commercial purposes. Chennai Cabs may choose at
            its sole discretion to transfer, sell, provide, or share such
            information with third parties, including to Chennai Cabs’s business
            partners for commercial purposes.
          </p>
        </section>
        <section>
          <h2 className="text-lg font-semibold mt-6 mb-[6px]">
            Online Links to Other Sites
          </h2>
          <p>
            Through our Site, users can link to other websites of third parties
            that provide useful information. Any information you provide on the
            linked pages is provided directly to this third-party and is subject
            to that third party’s provider’s privacy policy. Chennai Cabs is not
            responsible for the content or privacy practices of websites to
            which we link. Links from our site to third party’s or other sites
            are provided for your convenience. We encourage you to learn about
            the privacy practices of each website before providing them with any
            information.
          </p>
        </section>
        <section>
          <h2 className="text-lg font-semibold mt-6 mb-[6px]">
            Updating, Reviewing, or Correcting Personal Information
          </h2>
          <p>
            You may update, review, or correct your on-line account information
            on the Chennai Cabs Monitoring Platform at any time online by
            accessing your password-protected personal account administration
            page. You may also correct any other information of yourself by
            emailing us at admin@chennaicabs.in or calling us at the phone
            numbers published on https://chennaicabs.in/Contact-Us.
          </p>
        </section>
        <section>
          <h2 className="text-lg font-semibold mt-6 mb-[6px]">
            Questions or Concerns
          </h2>
          <p>
            If you have any questions or concerns about this privacy statement
            or would like to contact us for any reason, you can contact us at
            the phone numbers provided
          </p>
        </section>

        {/* <section>
          <h2 className="text-lg font-semibold  mt-6 mb-2 ">Terms of Use</h2>
          <ol className="list-decimal pl-5 space-y-1">
            <li>
              The content of the pages of this website is for your general
              information and use only. It is subject to change without notice.
            </li>
            <li>
              This website uses cookies to monitor browsing preferences. If you
              allow cookies to be used, the following personal information may
              be stored by us for use by third parties: IP Address, Location.
            </li>
            <li>
              Neither we nor any third parties provide any warranty or guarantee
              as to the accuracy, timeliness, performance, completeness, or
              suitability of the information and materials found or offered on
              this website for any particular purpose. You acknowledge that such
              information and materials may contain inaccuracies or errors, and
              we expressly exclude liability for any such inaccuracies or errors
              to the fullest extent permitted by law.
            </li>
            <li>
              Your use of any information or materials on this website is
              entirely at your own risk, and it shall be your responsibility to
              ensure that any products, services, or information available
              through this website meet your specific requirements.
            </li>
            <li>
              This website contains material owned by or licensed to us.
              Reproduction is prohibited other than in accordance with the
              copyright notice, which forms part of these terms and conditions.
            </li>
            <li>
              All trademarks reproduced on this website that are not the
              property of, or licensed to, the operator are acknowledged on the
              website.
            </li>
            <li>
              Unauthorized use of this website may give rise to a claim for
              damages and/or be a criminal offence.
            </li>
            <li>
              From time to time, this website may include links to other
              websites. These links are provided for your convenience to provide
              further information. They do not signify that we endorse the
              website(s), and we have no responsibility for the content of the
              linked website(s).
            </li>
            <li>
              Your use of this website and any dispute arising out of such use
              is subject to the laws of India.
            </li>
            <li>
              Specific offers may have additional Terms & Conditions, which the
              user must comply with if choosing to avail that offer.
            </li>
          </ol>
        </section> */}
      </div>
      <Footer />
    </Layout>
  );
};

export default PrivacyPolicy;
