import { Layout } from 'antd';
import React, { useEffect } from 'react';
import Header from '../layouts/Navbar';
import Footer from '../layouts/Footer';

const RefundPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <Header />
      <div className="container mt-5 mx-auto px-4 pb-16">
        <h1 className="text-3xl font-bold my-4 text-red-500">Cancellation and Returns Policy</h1>

        <section>
          <h2 className="text-lg font-semibold  mt-6 mb-[6px]"> </h2>
          <p>You may cancel your booking up to 24 hours prior to the scheduled journey time without incurring any cancellation charges for all our services.
            <br />
            However, if a cancellation or modification is requested within 24 hours of the pick-up time, the following rules will apply:</p>
        </section>

        <section>
          <h2 className="text-lg font-semibold mt-6 mb-[6px]"> 1. Multi-Day Trip:</h2>
          <p>The charge for the first day will be deducted from the total amount, and a refund will be issued to the user.</p>
        </section>
        <section>
          <h2 className="text-lg font-semibold mt-6 mb-[6px]"> 2. Single-Day Trip/Airport Transfer:</h2>
          <p>No refund will be issued to the user for cancellations or modifications within 24 hours of the pick-up time.</p>
        </section>
        <section>
          <h2 className="text-lg font-semibold mt-6 mb-[6px]"> 3. Airport Transfer:</h2>
          <p>No cancellation charges will apply if canceled at least 2 hours prior to the pickup time.</p>
        </section>


        <section>
          <h2 className="text-lg font-semibold  mt-6 mb-2 ">Refunds</h2>

          <p>If you are eligible for refunds based on the above "Cancellation and Returns" policy, the refund will be processed and remitted back to you within 5-7 working days.
            <br />
            If you encounter any issues or have inquiries, please contact us via email at admin@chennaicabs.in or call us at 98413 46080.</p>
        </section>

      </div>
      <Footer />
    </Layout>
  );
};

export default RefundPolicy;
