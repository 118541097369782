import React from "react";
import Layout from "../layouts/PageLayout";

function Contactus() {
  const Address = () => (
    <span className="font-sans text-base font-medium text-white">
      Plot No.11, Kandan Avenue, 24th Street 2nd Cross Street, <br />
      JB Estate, Avadi, Chennai, Thiruvallur, Tamil Nadu, 600054 <br />
      <span className="font-sans md:text-base font-bold bg-gradient-to-r from-[#87f9ff] via-yellow-200 to-yellow-300 bg-clip-text text-transparent">
        GSTIN: 33EAYPS1903L2Z2
      </span>
    </span>
  );

  return (
    <Layout className="w-screen">
      <div class="lg:hidden">
        <div className="px-4">
          <div className="my-6 text-center">
            <span className="text-[#00ADB5] text-2xl font-bold font-sans">
              CONTACT US
            </span>
            <div className="w-[150px] h-[4px] bg-[#00ADB5]  mt-1 mx-auto"></div>
          </div>
          <div className="flex flex-wrap justify-around w-full mb-10">
            <div className=" bg-[#222831] text-white  mt-2 text-center rounded-2xl px-6 py-20">
              <div className="mb-10">
                <div className="mb-1">
                  <span className="text-[#00ADB5] text-base font-semibold font-sans">
                    Call Us Now
                  </span>
                </div>
                <div>
                  <a href="tel:+919841346080" className="font-sans text-xl font-bold text-white">
                    +91 98413 46080
                  </a>
                </div>
                <div>
                  <a href="tel:+916382452160" className="font-sans text-xl font-bold text-white">
                    +91 63824 52160
                  </a>
                </div>
              </div>
              <div className="mb-10">
                <div className="mb-1">
                  <span className="text-[#00ADB5] text-base font-semibold font-sans">
                    Main Office
                  </span>
                </div>
                <div>
                  <Address />
                </div>
              </div>
              <div>
                <div className="mb-1">
                  <span className="text-[#00ADB5] text-base font-semibold font-sans">
                    Office Mail
                  </span>
                </div>
                <div>
                  <a href="mailto:admin@chennaicabs.in" className="font-sans text-base font-medium text-white">
                    admin@chennaicabs.in
                  </a>
                </div>
              </div>
            </div>
            {/* <div className=" bg-[#222831] mt-2  text-white mx-10 text-center rounded-2xl px-10 py-20">
              <p>
                <div className="mb-10">
                  <div className="mb-1">
                    <span className="text-[#00ADB5] text-base font-semibold font-sans">
                      Call Us Now
                    </span>
                  </div>
                  <div>
                    <span className="font-sans text-2xl font-bold text-white">
                      +91 98413 46080
                    </span>
                  </div>
                </div>
                <div className="mb-10">
                  <div className="mb-1">
                    <span className="text-[#00ADB5] text-base font-semibold font-sans">
                      Main Office
                    </span>
                  </div>
                  <div>
                    <span className="font-sans text-base font-medium text-white">
                      27, Maran Nagar, Poonamallee, Chennai, <br /> Thiruvallur,
                      Tamil Nadu - 600 056
                    </span>
                  </div>
                </div>
                <div>
                  <div className="mb-1">
                    <span className="text-[#00ADB5] text-base font-semibold font-sans">
                      Office Mail
                    </span>
                  </div>
                  <div>
                    <span className="font-sans text-base font-medium text-white">
                      admin@chennaicabs.in
                    </span>
                  </div>
                </div>
              </p>
            </div> */}
          </div>
          <div className="flex justify-center items-center text-white mb-10 mt-2 w-full">
            <div className="w-full max-w-[470px] text-center rounded-2xl bg-gray-600">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248688.39082200927!2d79.89045032956986!3d13.114631247270548!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52637232dfc431%3A0x210f3d3b6680071e!2sChennai%20Cabs!5e0!3m2!1sen!2sin!4v1721463320435!5m2!1sen!2sin" className="w-full h-[488px] px-2 py-2 rounded-2xl" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div>
      <div class="hidden lg:block">
        <div className="px-6 flex flex-col justify-center items-center">
          <div className="my-10 text-center">
            <span className="text-[#00ADB5] text-2xl font-bold font-sans">
              CONTACT US
            </span>
            <div className="flex justify-center w-full">
              <div className="w-[160px] mb-2 h-[4px] bg-[#00ADB5]  "></div>
            </div>
          </div>
          <div className="flex justify-around w-full mb-10 max-w-[1700px]">
            <div className=" bg-[#222831] text-white mx-10 mt-2 text-center rounded-2xl px-3 py-20">
              <div className="mb-10">
                <div className="mb-1">
                  <span className="text-[#00ADB5] text-base font-semibold font-sans">
                    Call Us Now
                  </span>
                </div>
                <div>
                  <a href="tel:+919841346080" className="font-sans text-2xl font-bold text-white">
                    +91 98413 46080
                  </a>
                </div>
                <div>
                  <a href="tel:+916382452160" className="font-sans text-2xl font-bold text-white">
                    +91 63824 52160
                  </a>
                </div>
              </div>
              <div className="mb-10">
                <div className="mb-1">
                  <span className="text-[#00ADB5] text-base font-semibold font-sans">
                    Main Office
                  </span>
                </div>
                <div>
                  <Address />
                </div>
              </div>
              <div>
                <div className="mb-1">
                  <span className="text-[#00ADB5] text-base font-semibold font-sans">
                    Office Mail
                  </span>
                </div>
                <div>
                  <a href="mailto:admin@chennaicabs.in" className="font-sans text-base font-medium text-white">
                    admin@chennaicabs.in
                  </a>
                </div>
              </div>
            </div>
            <div className=" bg-[#222831] flex justify-center items-center text-white mx-10  mt-2 text-center rounded-2xl ">
              {/* <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46019.48793713589!2d80.07685981419534!3d13.062046133370824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52614121e1d257%3A0xe4db6fac11186fac!2sMaran%20Nagar%2C%20Poonamallee!5e0!3m2!1sen!2sin!4v1686901373847!5m2!1sen!2sin"
                className="w-[372px] h-[488px] px-2 py-2 rounded-2xl"
              ></iframe> */}
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248688.39082200927!2d79.89045032956986!3d13.114631247270548!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52637232dfc431%3A0x210f3d3b6680071e!2sChennai%20Cabs!5e0!3m2!1sen!2sin!4v1721463320435!5m2!1sen!2sin" className="w-[400px] h-[488px] px-2 py-2 rounded-2xl" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Contactus;
