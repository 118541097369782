import React, { useState, useEffect } from "react";
import Layout from "../layouts/PageLayout";
import sedan from "../assets/images/Sedan-img.png";
import suvPlus from "../assets/images/suvPlus.png";
import suv from "../assets/images/suv-img.png";
import innova from "../assets/images/innova.com.png";
import tempo from "../assets/images/tempo.png";
import Backbutton from "../components/BackButton";
import TripInput from "../components/TripInput";
import { Link, useSearchParams } from "react-router-dom";
import TripApi from "../services/api/tripApi";
import loading from "../assets/icon/loading.gif";
import LocalTripCarCard from "../components/LocalTripCarCard";
import DateUtils from "../services/util/DateUtils";
import dayjs from "dayjs";

export default function LocalTrip() {
    const [params] = useSearchParams();
    const pickupLocation = params.get('pickup');
    const destinationLocation = params.get('destination');
    const pickUpDate = params.get('pickupDate');
    const pickUpTime = params.get('pickupTime');

    const [tripData, setTripData] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isTripValid, setIsTripValid] = useState(true);

    useEffect(() => {
        fetchDetails();
    }, [params]);

    const fetchDetails = async () => {
        setIsLoading(true);
        setIsTripValid(true);
        const data = {
            pickupLocation: pickupLocation,
            destinationLocation: destinationLocation,
            date: pickUpDate,
            time: pickUpTime,
            tripMode: 4,
            rewardPoints: 0,
        }
        const currentTimePlusMinutes = dayjs().add(0, 'minutes'); // minutes ahead
        const currentDate = dayjs().startOf("day");

        if (dayjs(pickUpTime, 'HH:mm').isBefore(currentTimePlusMinutes)) {
            if (DateUtils.convertDate(pickUpDate) === DateUtils.getCurrentDateFormatMonth()) {
                setIsLoading(false);
                return;
            }
        }
        if (dayjs(pickUpDate).isBefore(currentDate)) {
            setIsLoading(false);
            return;
        }
        const response = await TripApi.getDistance(data);
        setIsLoading(false);
        if (response?.code !== 200) {
            setIsTripValid(false);
            return;
        }
        setTripData(response.data);
    };

    const getCarImage = (car) => {
        if (car === "sedan") return sedan;
        if (car === "suv") return suv;
        if (car === "suv+") return suvPlus;
        if (car === "tempo") return tempo;
        if (car === "muv") return innova;
    };

    if (isTripValid === false) {
        return (
            <Layout>
                <div className="px-4 md:px-10">
                    <Backbutton />
                    <div className="my-3 md:my-10 text-center">
                        <span className="text-[#00ADB5] text-2xl font-bold font-sans">
                            Local Trip Booking
                        </span>
                    </div>
                    <TripInput
                        prePickupLocation={pickupLocation}
                        preDestinationLocation={destinationLocation}
                        prePickUpTime={pickUpTime}
                        prePickUpDate={pickUpDate}
                    />
                    <div className=" pb-16 px-5 md:px-10 space-y-4 text-xl md:text-2xl font-medium">
                        <h2>Please check the location(s)!</h2><h2>Local Trip is available only within 80kms from Chennai, Tamil Nadu.</h2>
                        <h2>Try booking with <Link to={`/OneWay-Trip?pickup=${pickupLocation}&destination=${destinationLocation}&pickupDate=${pickUpDate}&pickupTime=${pickUpTime}`} className="text-[#00ADB5] underline">Ony Way Trip</Link></h2>
                    </div>
                </div>
            </Layout>
        )
    }

    return (
        <Layout>
            <div className="px-4 md:px-10">
                <Backbutton />
                <div className="my-3 md:my-10 text-center">
                    <span className="text-[#00ADB5] text-2xl font-bold font-sans">
                        Local Trip Booking
                    </span>
                </div>
                <TripInput
                    prePickupLocation={pickupLocation}
                    preDestinationLocation={destinationLocation}
                    prePickUpTime={pickUpTime}
                    prePickUpDate={pickUpDate}
                />
                <div className="w-full flex flex-wrap justify-around">
                    {isLoading ?
                        <img src={loading} alt="Loading..." />
                        :
                        <>
                            {tripData && tripData?.map((item, index) => {
                                return (
                                    <div className="w-full md:w-auto max-w-[400px]">
                                        <LocalTripCarCard
                                            key={index}
                                            cardNameSpan={item.modelName}
                                            carImage={getCarImage(item.carModeName?.toLowerCase())}
                                            smallCardCarName={item.carModeName?.toUpperCase()}
                                            carType={parseInt(item.carModeId)}
                                            smallCardNoofPerson={item.capacity}
                                            carPrice="Rs.12"
                                            distance={item.distanceInKm}
                                            estimatedRate={item.estimatedRate}
                                            index={item.carModeName}
                                            pickupLocation={pickupLocation}
                                            destinationLocation={destinationLocation}
                                            pickUpTime={pickUpTime}
                                            pickUpDate={pickUpDate}
                                            daysDiff={item.days}
                                            distanceUpto={item.distanceUpto}
                                            estimatedRateWithDriver={item.estimatedRateWithDriverFee}
                                            driverFee={item.totalDriverFee}
                                            fare={item.farePerKm}
                                            tripMode={4}
                                        />
                                    </div>
                                )
                            })}
                        </>
                    }
                </div>
            </div>
        </Layout>
    );
}