import axios from "axios";

const TripApi = {
    getDistance: async (data) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}common/rideEstimateFareBookingSite`,
                data
            );
            return response.data;
        } catch (error) {
            return error.response && error.response.data;
        }
    },
    bookRide: async (data) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}common/bookRide`,
                data
            );
            return response.data;
        } catch (error) {
            return error.response && error.response.data;
        }
    },
    getOTP: async (data) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}common/customer/login`,
                data
            );
            return response;
        } catch (error) {
            return error.response && error.response.data;
        }
    },
    verifyOTP: async (data) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}common/customer/verifyOTP`,
                data
            );
            return response.data;
        } catch (error) {
            return error.response && error.response.data;
        }
    }
}

export default TripApi;