import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import homeservice from "../assets/images/homeservice.jpg";
import localRentals from "../assets/images/localRental2.jpg";
import oneWay from "../assets/images/oneWay.jpg";
import marriage from "../assets/images/marriage.jpg";
import roundTrip from "../assets/images/roundTrip.jpg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import Servicecard from "./HomeServiceCard";

export default function SimpleSlider() {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        // navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <Servicecard
            className="rounded-xl"
            imgSrc={localRentals}
            alt="homeservice-icon"
            span="LOCAL RENTALS"
            paragraph="Our local rental service offers well-maintained cars and courteous drivers for hassle-free travel in the city. Whether you need to visit multiple places or explore the city, we have got you covered at affordable rates."
          />
        </SwiperSlide>
        <SwiperSlide>
          <Servicecard
            className="rounded-xl"
            imgSrc={oneWay}
            alt="homeservice-icon"
            span="ONE WAY DROP"
            paragraph="Our one-way drop service allows you to travel from one location to another with ease and comfort. You can choose from a wide range of well-maintained cars and experienced drivers to ensure a safe and on-time journey."
          />
        </SwiperSlide>
        <SwiperSlide>
          <Servicecard
            className="rounded-xl"
            imgSrc={roundTrip}
            alt="homeservice-icon"
            span="ROUND TRIP"
            paragraph="Our round trip cab service offers you the convenience of booking a cab for a full day or multiple days. With well-maintained cars and experienced drivers, you can explore the city or travel to multiple destinations at your own pace and comfort."
          />
        </SwiperSlide>
        <SwiperSlide>
          <Servicecard
            className="rounded-xl"
            imgSrc={homeservice}
            alt="homeservice-icon"
            span="EMPLOYEE FLEET"
            paragraph="Our corporate fleet service provides customized transportation solutions for businesses, ensuring safe and comfortable travel for employees and clients. With a range of well-maintained cars and experienced drivers."
          />
        </SwiperSlide>
        <SwiperSlide>
          <Servicecard
            className="rounded-xl"
            imgSrc={marriage}
            alt="homeservice-icon"
            span="WEDDING PACKAGE"
            paragraph="Our wedding package offers a range of well-decorated cars and experienced drivers to add a touch of elegance to your special day. With customizable packages to suit your needs, you can rely on us to provide hassle-free and memorable for your wedding party."
          />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
