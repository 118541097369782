import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import CClogo from "../assets/images/logo.png";
import { AiOutlineMenu } from "react-icons/ai";
import { AiFillCloseCircle } from "react-icons/ai";
import telephone from "../assets/images/telephone.png";
import "./Call.css";

function Header() {

  const themeMode = "dark";
  const [isOpen, setIsOpen] = useState(false);
  const handleMenu = () => {
    setIsOpen(!isOpen);
  };
  const location = useLocation();

  const navItemClasses = {
    "/": location.pathname === "/" ? "text-teal-500" : "text-white",
    "/About-Us":
      location.pathname === "/About-Us" ? "text-teal-500 transition-all" : "text-white",
    "/Our-Services":
      location.pathname === "/Our-Services" ? "text-teal-500" : "text-white",
    "/Testimonial":
      location.pathname === "/Testimonial" ? "text-teal-500" : "text-white",
    "/Contact-Us":
      location.pathname === "/Contact-Us" ? "text-teal-500" : "text-white",
  };

  return (
    <>
      <div className={`top-0 sticky z-20 bg-[#222831]`}>
        <div className="hidden relative md:block">
          <div className="flex">
            <nav className="flex items-center justify-between w-full h-16 px-10 ">
              <Link to="/">
                <img className="w-[66px] h-[46px]" src={CClogo} alt="logo" />
              </Link>
              <div className="max-sm:hidden">
                <ul className="flex">
                  <Link to="/">
                    <li
                      className={`pr-10 text-[15px] font-sans ${navItemClasses["/"]} font-semibold hover:text-[#00ADB5] transition-all`}
                    >
                      Home
                    </li>
                  </Link>
                  <Link to="/About-Us">
                    <li
                      className={`pr-10 text-[15px] font-sans ${navItemClasses["/About-Us"]} font-semibold hover:text-[#00ADB5] transition-all`}
                    >
                      About Us
                    </li>
                  </Link>
                  <Link to="/Our-Services">
                    <li
                      className={`pr-10 text-[15px] font-sans ${navItemClasses["/Our-Services"]} font-semibold hover:text-[#00ADB5] transition-all`}
                    >
                      Our Services
                    </li>
                  </Link>
                  <Link to="/Testimonial">
                    <li
                      className={`pr-10 text-[15px] font-sans ${navItemClasses["/Testimonial"]} font-semibold hover:text-[#00ADB5] transition-all`}
                    >
                      Testimonial
                    </li>
                  </Link>
                  <Link to="/Contact-Us">
                    <li
                      className={`text-[15px] font-sans ${navItemClasses["/Contact-Us"]} font-semibold hover:text-[#00ADB5] transition-all`}
                    >
                      Contact Us
                    </li>
                  </Link>
                </ul>
              </div>
              {!isOpen && (
                <AiOutlineMenu
                  onClick={handleMenu}
                  className={`text-white text-3xl cursor-pointer md:hidden`}
                />
              )}
            </nav>
          </div>

          {isOpen && (
            <>
              <AiFillCloseCircle
                onClick={handleMenu}
                className={`text-white text-3xl font-bold absolute  right-10 top-5  cursor-pointer md:hidden`}
              />
              <div className=" inset-0 absolute h-[320px] left-0 right-0 flex flex-col bg-opacity-10 bg-gray-50 backdrop-blur-[15px] top-[58px]">
                <div className="flex justify-center px-3 overflow-y-auto ">
                  <ul className="flex flex-col items-center justify-start w-full h-screen font-medium">
                    <li>
                      <Link
                        to="/"
                        className="flex items-center w-screen p-2 px-5 py-5 bg-teal-500 justify-centertext-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        <svg
                          aria-hidden="true"
                          className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-house-door-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z" />
                        </svg>
                        <span className="ml-3">Home</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/About-Us"
                        className="flex items-center justify-center w-screen p-2 px-5 py-5 text-gray-900 bg-teal-500 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        <svg
                          aria-hidden="true"
                          className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-info-square-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                        </svg>
                        <span className="flex-1 ml-3 text-[#222831] whitespace-nowrap">
                          About Us
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Our-Services"
                        className="flex items-center justify-center w-screen p-2 px-5 py-5 text-gray-900 bg-teal-500 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        <svg
                          aria-hidden="true"
                          className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-car-front-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM2.906 5.189a.51.51 0 0 0 .497.731c.91-.073 3.35-.17 4.597-.17 1.247 0 3.688.097 4.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 11.691 3H4.309a.5.5 0 0 0-.447.276L2.906 5.19Z" />
                        </svg>
                        <span className="flex-1 ml-3 text-[#222831] whitespace-nowrap">
                          Our Services
                        </span>
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/Testimonial"
                        href="#"
                        className="flex items-center justify-center w-screen p-2 px-5 py-5 text-gray-900 bg-teal-500 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        <svg
                          aria-hidden="true"
                          className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-people-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                        </svg>
                        <span className="flex-1 ml-3 text-[#222831] whitespace-nowrap">
                          Testimonials
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Contact-Us"
                        className="flex items-center justify-center w-screen p-2 px-5 py-5 text-gray-900 bg-teal-500 border-b-2 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        <svg
                          aria-hidden="true"
                          className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-person-rolodex"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                          <path d="M1 1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h.5a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h.5a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H6.707L6 1.293A1 1 0 0 0 5.293 1H1Zm0 1h4.293L6 2.707A1 1 0 0 0 6.707 3H15v10h-.085a1.5 1.5 0 0 0-2.4-.63C11.885 11.223 10.554 10 8 10c-2.555 0-3.886 1.224-4.514 2.37a1.5 1.5 0 0 0-2.4.63H1V2Z" />
                        </svg>
                        <span className="flex-1 ml-3 text-[#222831] whitespace-nowrap">
                          Contact Us
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                onClick={handleMenu}
                className=" clickHere absolute h-[320px] left-0 right-0 flex flex-col bg-opacity-10 bg-gray-200  top-[378px]"
              ></div>
            </>
          )}
          <div className="fixed bottom-[20px]  ring-animation left-7  z-50 bg-[#2196F3] hover:bg-teal-600  rounded-full shadow-[0 2px 4px 0 rgba(0, 0, 0, 0.10), 0 1px 2px 0 rgba(0, 0, 0, 0.06)]">
            <a href="tel:+919841346080">
              <img
                className="p-1"
                src={telephone}
                width={60}
                height={60}
                alt="call"
              />
            </a>
          </div>
        </div>
      </div >
      <div className={`top-0 h-16 lg:h-auto sticky z-20 bg-[#222831]  block md:hidden`}>
        <div className="clickHere">
          <div className="flex">
            <nav className="flex items-center justify-between w-full h-16 px-3">
              <div className="flex items-center justify-between w-full">
                {!isOpen ? (
                  <AiOutlineMenu
                    onClick={handleMenu}
                    className={`text-white text-[25px]  cursor-pointer md:hidden`}
                  />
                ) : (
                  <>
                    <AiFillCloseCircle
                      className={` text-2xl  cursor-pointer md:hidden`}
                    />
                  </>
                )}
                <div className="flex items-center justify-center w-full">
                  <div>
                    <h1 className="font-serif text-xl text-white ">
                      Chennai Cabs
                    </h1>
                  </div>
                </div>
                <div className="">
                  <Link to={"/"}>
                    <img className="w-[45px] h-[25px]" src={CClogo} alt="logo" />
                  </Link>
                </div>
              </div>
            </nav>
          </div>
          <div className="fixed bottom-[30px] left-7  z-50 bg-[#2196F3] hover:bg-teal-600  rounded-full shadow-[0 2px 4px 0 rgba(0, 0, 0, 0.10), 0 1px 2px 0 rgba(0, 0, 0, 0.06)]">
            <a href="tel:+919841346080">
              <img
                className="p-1"
                src={telephone}
                width={60}
                height={60}
                alt="call"
              />
            </a>
          </div>

          {isOpen && (
            <>
              <AiFillCloseCircle
                onClick={handleMenu}
                className={`text-white  text-4xl  font-bold absolute  left-2 top-3 cursor-pointer md:hidden`}
              />
              <div className=" inset-0 absoulte    left-0 right-0 flex flex-col bg-opacity-10 bg-transparent  top-[58px]">
                <div className="flex justify-center px-3 overflow-y-auto ">
                  <ul className="flex flex-col items-center justify-start w-full h-screen font-medium">
                    <li>
                      <Link
                        to="/"
                        className="flex items-center w-screen p-2 px-5 py-5 bg-teal-500 justify-centertext-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        <svg
                          aria-hidden="true"
                          className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-house-door-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z" />
                        </svg>
                        <span className="ml-3 text-[#222831]">Home</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/About-Us"
                        className="flex items-center justify-center w-screen p-2 px-5 py-5 text-gray-900 bg-teal-500 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        <svg
                          aria-hidden="true"
                          className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-info-square-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                        </svg>
                        <span className="flex-1 ml-3 text-[#222831] whitespace-nowrap">
                          About Us
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Our-Services"
                        className="flex items-center justify-center w-screen p-2 px-5 py-5 text-gray-900 bg-teal-500 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        <svg
                          aria-hidden="true"
                          className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-car-front-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM2.906 5.189a.51.51 0 0 0 .497.731c.91-.073 3.35-.17 4.597-.17 1.247 0 3.688.097 4.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 11.691 3H4.309a.5.5 0 0 0-.447.276L2.906 5.19Z" />
                        </svg>
                        <span className="flex-1 ml-3 text-[#222831] whitespace-nowrap">
                          Our Services
                        </span>
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/Testimonial"
                        href="#"
                        className="flex items-center justify-center w-screen p-2 px-5 py-5 text-gray-900 bg-teal-500 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        <svg
                          aria-hidden="true"
                          className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-people-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                        </svg>
                        <span className="flex-1 ml-3 text-[#222831] whitespace-nowrap">
                          Testimonials
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Contact-Us"
                        className="flex items-center justify-center w-screen p-2 px-5 py-5 text-gray-900 bg-teal-500 border-b-2 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        <svg
                          aria-hidden="true"
                          className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-person-rolodex"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                          <path d="M1 1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h.5a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h.5a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H6.707L6 1.293A1 1 0 0 0 5.293 1H1Zm0 1h4.293L6 2.707A1 1 0 0 0 6.707 3H15v10h-.085a1.5 1.5 0 0 0-2.4-.63C11.885 11.223 10.554 10 8 10c-2.555 0-3.886 1.224-4.514 2.37a1.5 1.5 0 0 0-2.4.63H1V2Z" />
                        </svg>
                        <span className="flex-1 ml-3 text-[#222831] whitespace-nowrap">
                          Contact Us
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                onClick={handleMenu}
                className=" clickHere absolute  left-0 right-0 flex flex-col    top-[372px]"
              ></div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Header;
