import React, { useState, useEffect } from "react";
import Layout from "../layouts/PageLayout";
import OneWayTripCarCard from "../components/OneWayTripCarCard";
import sedan from "../assets/images/Sedan-img.png";
import suv from "../assets/images/suv-img.png";
import Backbutton from "../components/BackButton";
import TripInput from "../components/TripInput";
import { useSearchParams } from "react-router-dom";
import TripApi from "../services/api/tripApi";
import loading from "../assets/icon/loading.gif";
import DateUtils from "../services/util/DateUtils";
import dayjs from "dayjs";

function OneWayTrip() {

  const [params] = useSearchParams();
  const pickupLocation = params.get('pickup');
  const destinationLocation = params.get('destination');
  const pickUpDate = params.get('pickupDate');
  const pickUpTime = params.get('pickupTime');

  const [tripData, setTripData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchDetails();
  }, [params]);

  const fetchDetails = async () => {
    setIsLoading(true);
    const data = {
      pickupLocation: pickupLocation,
      destinationLocation: destinationLocation,
      date: pickUpDate,
      time: pickUpTime,
      tripMode: 1,
      rewardPoints: 0,
    }
    const currentTimePlusMinutes = dayjs().add(0, 'minutes'); // minutes ahead
    const currentDate = dayjs().startOf("day");

    if (dayjs(pickUpTime, 'HH:mm').isBefore(currentTimePlusMinutes)) {
      if (DateUtils.convertDate(pickUpDate) === DateUtils.getCurrentDateFormatMonth()) {
        setIsLoading(false);
        return;
      }
    }
    if (dayjs(pickUpDate).isBefore(currentDate)) {
      setIsLoading(false);
      return;
    }
    const response = await TripApi.getDistance(data);
    if (response.code !== 200) return;
    setTripData(response.data);
    setIsLoading(false);
  };

  return (
    <Layout>
      <div className="px-4 md:px-10">
        <Backbutton />
        <div className="text-center my-3 md:my-10">
          <span className="text-[#00ADB5] text-2xl font-bold font-sans">
            One Way Trip Booking
          </span>
        </div>
        <TripInput
          prePickupLocation={pickupLocation}
          preDestinationLocation={destinationLocation}
          prePickUpTime={pickUpTime}
          prePickUpDate={pickUpDate}
        />
        <div className="w-full flex flex-wrap justify-around">
          {isLoading ?
            <img src={loading} alt="Loading..." />
            :
            <>
              {tripData && tripData?.map((item, index) => {
                return (
                  <div className="w-full md:w-auto max-w-[400px]">
                    <OneWayTripCarCard
                      key={index}
                      cardNameSpan={item.carModeName?.toUpperCase()}
                      carImage={item.carModeName?.toUpperCase() == "SEDAN" ? sedan : suv}
                      carType={parseInt(item.carModeId)}
                      smallCardCarName={item.carModeName}
                      smallCardNoofPerson={item.capacity}
                      carPrice="Rs.13"
                      estimatedRate={item.estimatedRate}
                      estimatedRateWithDriver={item.estimatedRateWithDriverFee}
                      index={"sedan"}
                      pickupLocation={pickupLocation}
                      destinationLocation={destinationLocation}
                      pickUpTime={pickUpTime}
                      pickUpDate={pickUpDate}
                      distance={item.distanceInKm}
                      driverFee={item.totalDriverFee}
                      fare={item.farePerKm}
                      tripMode={1}
                      distanceUpto={item.distanceUpto}
                    />
                  </div>
                )
              })}
            </>
          }
        </div>
      </div>
    </Layout>
  );
}

export default OneWayTrip;
