import React from "react";
import Layout from "../layouts/PageLayout";

function Testimonial() {
  return (
    <Layout className="w-screen">
      <div class="lg:hidden">
        <div className="px-2">
          <div className="my-10 text-center">
            <span className="text-[#00ADB5] text-2xl font-bold font-sans">
              TESTIMONIALS
            </span>
            <div className="w-[172px] h-[4px] bg-[#00ADB5] mx-auto "></div>
          </div>
          <div>
            <div className="flex flex-wrap justify-between w-full gap-10 mb-10">
              <div className="mx-4 ">
                <div className="p-6 text-center bg-white rounded-t-lg">
                  <p className="text-sm text-[#393E46] font-normal font-sans">
                    Excellent cab in my travel experience. On time pick up and
                    professional drivers. Safety wise good one. Overall nice
                    travel experience with Chennai cabs. Would recommend to my
                    friends and family.
                  </p>
                </div>
                <div className="bg-[#222831] text-center py-3 rounded-b-lg">
                  <span className="font-sans text-base font-semibold text-white">
                    Karthi Keyan
                  </span>
                </div>
              </div>

              <div className="mx-4 ">
                <div className="p-6 text-center bg-white rounded-t-lg">
                  <p className="text-sm text-[#393E46] font-normal font-sans">
                    It is a very good service. Cabs are easily available and on
                    time drivers are very professional and punctual. Highly
                    recommended.
                  </p>
                </div>
                <div className="bg-[#222831] text-center py-3 rounded-b-lg">
                  <span className="font-sans text-base font-semibold text-white">
                    Janani S M
                  </span>
                </div>
              </div>

              <div className="mx-4 ">
                <div className="p-6 text-center bg-white rounded-t-lg">
                  <p className="text-sm text-[#393E46] font-normal font-sans">
                    Felt very safe and secured to travel as a woman passenger. I
                    recommend this cab service as economical as well as fully
                    sanitized during this pandemic time. The driver was very
                    patient and well behaved. I m fully satisfied with the
                    service.
                  </p>
                </div>
                <div className="bg-[#222831] text-center py-3 rounded-b-lg">
                  <span className="font-sans text-base font-semibold text-white">
                    Bhuvana Jain
                  </span>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap justify-between w-full gap-10 mb-10">
              <div className="mx-4 ">
                <div className="p-6 text-center bg-white rounded-t-lg">
                  <p className="text-sm text-[#393E46] font-normal font-sans">
                    I have travelled from Madurai to Bangalore, it's a wonderful
                    experience. Nice driver with well maintained vehicles. I
                    travelled with so many operators, but Chennai Cab given a
                    wonderful service with affordable cost. Thank you Chennai
                    Cab.
                  </p>
                </div>
                <div className="bg-[#222831] text-center py-3 rounded-b-lg">
                  <span className="font-sans text-base font-semibold text-white">
                    Saravanan
                  </span>
                </div>
              </div>

              <div className="mx-4 ">
                <div className="p-6 text-center bg-white rounded-t-lg">
                  <p className="text-sm text-[#393E46] font-normal font-sans">
                    Booked one way trip from kalayarkoil to chennai. Reasonable
                    price in comfortable car. Driver also friendly and supported
                    in all the ways. Kudos to Chennai cabs for wonderful service
                  </p>
                </div>
                <div className="bg-[#222831] text-center py-3 rounded-b-lg">
                  <span className="font-sans text-base font-semibold text-white">
                    Thandaiah Prabu
                  </span>
                </div>
              </div>

              <div className="mx-4 ">
                <div className="p-6 text-center bg-white rounded-t-lg">
                  <p className="text-sm text-[#393E46] font-normal font-sans">
                    Had a great drive.. it's safe and secure... Chennai cabs is
                    most likely to be preferable for ur journeys. Drivers ensure
                    the customers safety.. cabs are sanitized often.. Really had
                    a great experience with Chennai cabs
                  </p>
                </div>
                <div className="bg-[#222831] text-center py-3 rounded-b-lg">
                  <span className="font-sans text-base font-semibold text-white">
                    Scinthia Clarinda
                  </span>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap justify-between w-full gap-10 mb-10">
              <div className="mx-4 ">
                <div className="p-6 text-center bg-white rounded-t-lg">
                  <p className="text-sm text-[#393E46] font-normal font-sans">
                    I have booked cab for Chennai to vellore..They charged
                    onlyRs2999 only for round trip..i have saved rs400 compare
                    than other travels..safe journey,nice driving,good condition
                    car and value for money.they provided excellent service.
                  </p>
                </div>
                <div className="bg-[#222831] text-center py-3 rounded-b-lg">
                  <span className="font-sans text-base font-semibold text-white">
                    Gajendhiran S
                  </span>
                </div>
              </div>

              <div className="mx-4 ">
                <div className="p-6 text-center bg-white rounded-t-lg">
                  <p className="text-sm text-[#393E46] font-normal font-sans">
                    Really nice experience all over the trip ! Cab driver was
                    well experienced in tourist taxi and helped us a lot .
                  </p>
                </div>
                <div className="bg-[#222831] text-center py-3 rounded-b-lg">
                  <span className="font-sans text-base font-semibold text-white">
                    Sabarish
                  </span>
                </div>
              </div>

              <div className="mx-4 ">
                <div className="p-6 text-center bg-white rounded-t-lg">
                  <p className="text-sm text-[#393E46] font-normal font-sans">
                    Very seamless experience from booking through whatsapp chat,
                    ontime update from driver and the comfortable journey.
                  </p>
                </div>
                <div className="bg-[#222831] text-center py-3 rounded-b-lg">
                  <span className="font-sans text-base font-semibold text-white">
                    Periyasamy Thoppalan
                  </span>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap justify-between w-full gap-10 mb-10">
              <div className="mx-4 ">
                <div className="p-6 text-center bg-white rounded-t-lg">
                  <p className="text-sm text-[#393E46] font-normal font-sans">
                    Good service.. Chennai Cabs is providing excellent service..
                    Their cabs are clean.. Cost vise reasonable.. I have used
                    their one way drop and two way service.. I am happy with
                    their service..Thanks Chennai Cabs..
                  </p>
                </div>
                <div className="bg-[#222831] text-center py-3 rounded-b-lg">
                  <span className="font-sans text-base font-semibold text-white">
                    K Madhan Kumar
                  </span>
                </div>
              </div>

              <div className="mx-4 ">
                <div className="p-6 text-center bg-white rounded-t-lg">
                  <p className="text-sm text-[#393E46] font-normal font-sans">
                    Good service and reasonable fare compare with other
                    travels,,.. Highly recommended.. Thanking you
                  </p>
                </div>
                <div className="bg-[#222831] text-center py-3 rounded-b-lg">
                  <span className="font-sans text-base font-semibold text-white">
                    Antony Charles
                  </span>
                </div>
              </div>

              <div className="mx-4 ">
                <div className="p-6 text-center bg-white rounded-t-lg">
                  <p className="text-sm text-[#393E46] font-normal font-sans">
                    Booked one way trip from Coimbatore to chennai.journey was
                    very comfortable and reasonable fare.i am very happy to use
                    chennai cabs..
                  </p>
                </div>
                <div className="bg-[#222831] text-center py-3 rounded-b-lg">
                  <span className="font-sans text-base font-semibold text-white">
                    Dayana
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hidden lg:block">
        <div className="px-10">
          <div className="my-10 text-center">
            <span className="text-[#00ADB5] text-2xl font-bold font-sans">
              TESTIMONIALS
            </span>
            <div className="w-[170px] h-[4px] bg-[#00ADB5] mx-auto "></div>
          </div>

          <div className="flex justify-between w-full mb-10">
            <div className="w-4/12 mx-4 ">
              <div className="p-6 text-center bg-white rounded-t-lg">
                <p className="text-sm text-[#393E46] font-normal font-sans">
                  Excellent cab in my travel experience. On time pick up and
                  professional drivers. Safety wise good one. Overall nice
                  travel experience with Chennai cabs. Would recommend to my
                  friends and family.
                </p>
              </div>
              <div className="bg-[#222831] text-center py-3 rounded-b-lg">
                <span className="font-sans text-base font-semibold text-white">
                  Karthi Keyan
                </span>
              </div>
            </div>

            <div className="w-4/12 mx-4 ">
              <div className="p-6 text-center bg-white rounded-t-lg">
                <p className="text-sm text-[#393E46] font-normal font-sans">
                  It is a very good service. Cabs are easily available and on
                  time drivers are very professional and punctual. Highly
                  recommended.
                </p>
              </div>
              <div className="bg-[#222831] text-center py-3 rounded-b-lg">
                <span className="font-sans text-base font-semibold text-white">
                  Janani S M
                </span>
              </div>
            </div>

            <div className="w-4/12 mx-4 ">
              <div className="p-6 text-center bg-white rounded-t-lg">
                <p className="text-sm text-[#393E46] font-normal font-sans">
                  Felt very safe and secured to travel as a woman passenger. I
                  recommend this cab service as economical as well as fully
                  sanitized during this pandemic time. The driver was very
                  patient and well behaved. I m fully satisfied with the
                  service.
                </p>
              </div>
              <div className="bg-[#222831] text-center py-3 rounded-b-lg">
                <span className="font-sans text-base font-semibold text-white">
                  Bhuvana Jain
                </span>
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full mb-10">
            <div className="w-4/12 mx-4 ">
              <div className="p-6 text-center bg-white rounded-t-lg">
                <p className="text-sm text-[#393E46] font-normal font-sans">
                  I have travelled from Madurai to Bangalore, it's a wonderful
                  experience. Nice driver with well maintained vehicles. I
                  travelled with so many operators, but Chennai Cab given a
                  wonderful service with affordable cost. Thank you Chennai Cab.
                </p>
              </div>
              <div className="bg-[#222831] text-center py-3 rounded-b-lg">
                <span className="font-sans text-base font-semibold text-white">
                  Saravanan
                </span>
              </div>
            </div>

            <div className="w-4/12 mx-4 ">
              <div className="p-6 text-center bg-white rounded-t-lg">
                <p className="text-sm text-[#393E46] font-normal font-sans">
                  Booked one way trip from kalayarkoil to chennai. Reasonable
                  price in comfortable car. Driver also friendly and supported
                  in all the ways. Kudos to Chennai cabs for wonderful service
                </p>
              </div>
              <div className="bg-[#222831] text-center py-3 rounded-b-lg">
                <span className="font-sans text-base font-semibold text-white">
                  Thandaiah Prabu
                </span>
              </div>
            </div>

            <div className="w-4/12 mx-4 ">
              <div className="p-6 text-center bg-white rounded-t-lg">
                <p className="text-sm text-[#393E46] font-normal font-sans">
                  Had a great drive.. it's safe and secure... Chennai cabs is
                  most likely to be preferable for ur journeys. Drivers ensure
                  the customers safety.. cabs are sanitized often.. Really had a
                  great experience with Chennai cabs
                </p>
              </div>
              <div className="bg-[#222831] text-center py-3 rounded-b-lg">
                <span className="font-sans text-base font-semibold text-white">
                  Scinthia Clarinda
                </span>
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full mb-10">
            <div className="w-4/12 mx-4 ">
              <div className="p-6 text-center bg-white rounded-t-lg">
                <p className="text-sm text-[#393E46] font-normal font-sans">
                  I have booked cab for Chennai to vellore..They charged
                  onlyRs2999 only for round trip..i have saved rs400 compare
                  than other travels..safe journey,nice driving,good condition
                  car and value for money.they provided excellent service.
                </p>
              </div>
              <div className="bg-[#222831] text-center py-3 rounded-b-lg">
                <span className="font-sans text-base font-semibold text-white">
                  Gajendhiran S
                </span>
              </div>
            </div>

            <div className="w-4/12 mx-4 ">
              <div className="p-6 text-center bg-white rounded-t-lg">
                <p className="text-sm text-[#393E46] font-normal font-sans">
                  Really nice experience all over the trip ! Cab driver was well
                  experienced in tourist taxi and helped us a lot .
                </p>
              </div>
              <div className="bg-[#222831] text-center py-3 rounded-b-lg">
                <span className="font-sans text-base font-semibold text-white">
                  Sabarish
                </span>
              </div>
            </div>

            <div className="w-4/12 mx-4 ">
              <div className="p-6 text-center bg-white rounded-t-lg">
                <p className="text-sm text-[#393E46] font-normal font-sans">
                  Very seamless experience from booking through whatsapp chat,
                  ontime update from driver and the comfortable journey.
                </p>
              </div>
              <div className="bg-[#222831] text-center py-3 rounded-b-lg">
                <span className="font-sans text-base font-semibold text-white">
                  Periyasamy Thoppalan
                </span>
              </div>
            </div>
          </div>

          <div className="flex justify-between w-full mb-10">
            <div className="w-4/12 mx-4 ">
              <div className="p-6 text-center bg-white rounded-t-lg">
                <p className="text-sm text-[#393E46] font-normal font-sans">
                  Good service.. Chennai Cabs is providing excellent service..
                  Their cabs are clean.. Cost vise reasonable.. I have used
                  their one way drop and two way service.. I am happy with their
                  service..Thanks Chennai Cabs..
                </p>
              </div>
              <div className="bg-[#222831] text-center py-3 rounded-b-lg">
                <span className="font-sans text-base font-semibold text-white">
                  K Madhan Kumar
                </span>
              </div>
            </div>

            <div className="w-4/12 mx-4 ">
              <div className="p-6 text-center bg-white rounded-t-lg">
                <p className="text-sm text-[#393E46] font-normal font-sans">
                  Good service and reasonable fare compare with other
                  travels,,.. Highly recommended.. Thanking you
                </p>
              </div>
              <div className="bg-[#222831] text-center py-3 rounded-b-lg">
                <span className="font-sans text-base font-semibold text-white">
                  Antony Charles
                </span>
              </div>
            </div>

            <div className="w-4/12 mx-4 ">
              <div className="p-6 text-center bg-white rounded-t-lg">
                <p className="text-sm text-[#393E46] font-normal font-sans">
                  Booked one way trip from Coimbatore to chennai.journey was
                  very comfortable and reasonable fare.i am very happy to use
                  chennai cabs..
                </p>
              </div>
              <div className="bg-[#222831] text-center py-3 rounded-b-lg">
                <span className="font-sans text-base font-semibold text-white">
                  Dayana
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default Testimonial;