import React from "react";
import backbutton from "../assets/images/backbutton.png"
import { useNavigate } from "react-router-dom";

function Backbutton() {
    const navigate = useNavigate();
    return (
        <button className="mt-10" onClick={() => navigate("/")}>
            <img className="h-5 w-5" src={backbutton} alt="backbutton-icon" />
        </button>
    );
}

export default Backbutton;
