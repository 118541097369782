import React, { useEffect, useState, useRef } from "react";
import taxiiconwhite from "../assets/images/taxi-icon-white.png";
import person from "../assets/images/person-icon.png";
import ac from "../assets/images/Ac-icon.png";
import success from "../../src/assets/icon/check-circle.svg";
import CClogo from "../assets/images/logo.png";
import close from "../assets/images/close.png";
import iIcon from "../../src/assets/icon/i-icon 1.svg";
import alert from "../../src/assets/icon/alert-triangle.svg";
import taxiicon from "../assets/images/taxi-icon.png";
import location from "../assets/images/location-icon.png";
import calender from "../assets/images/calendar-icon.png";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import TripApi from "../services/api/tripApi";
import WhatsappApi from "../services/api/whatsappApi";
import { message } from "antd";
import Confetti from 'react-confetti';
import OTPField from "./OTPField";
import DateUtils from "../services/util/DateUtils";
import { useMapLocationUrl } from "../services/context/MapLocation";

export default function LocalTripCarCard(props) {
    const [visibleModal, setVisibleModal] = useState(false);
    const [visibleLogin, setVisibleLogin] = useState(false);
    const [visibleOtp, setVisibleOtp] = useState(false);
    const [visibleSummary, setVisibleSummary] = useState(false);
    const [visibleConfirm, setVisibleConfirm] = useState(false);
    const [username, setUsername] = useState("");
    const [nameError, setNameError] = useState("");
    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [otp, setOtp] = useState(new Array(4).fill(""));
    const [otpError, setOtpError] = useState("");
    const [bookingId, setBookingId] = useState("");
    const [timer, setTimer] = useState(60);
    const [customerId, setCustomerId] = useState("");

    const [confetti, setConfetti] = useState(false);

    const { pickupLocationMapUrl, dropLocationMapUrl } = useMapLocationUrl();

    const MIN_KM_LOCAL_TRIP = 20;

    const scrollContainerRef = useRef(null);
    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;

        const preventScroll = (event) => {
            event.preventDefault();
        };

        scrollContainer.addEventListener("wheel", preventScroll);
        scrollContainer.addEventListener("touchmove", preventScroll);

        return () => {
            scrollContainer.removeEventListener("wheel", preventScroll);
            scrollContainer.removeEventListener("touchmove", preventScroll);
        };
    }, []);

    const formattedDate = dayjs(props.pickUpDate).format("DD-MM-YYYY");

    const onSignInSubmit = async (e) => {
        e.preventDefault();
        if (!handleFormValidationSignIn()) return;
        const data = {
            userName: username,
            mobileNumber: phone,
            userCreatedBy: 2 // website
        }
        const response = await TripApi.getOTP(data);
        if (response?.message?.includes("Spamming detected")) {
            setPhoneError("Spamming detected, please try again later or with different number.");
            return;
        };
        if (!response.status) {
            setPhoneError("Verify your phone number and try again.");
            return;
        };
        setVisibleOtp(true);
    };

    const handleFormValidationSignIn = () => {
        let formIsValid = true;

        if (!phone) {
            formIsValid = false;
            setPhoneError("Please enter your mobile number.");
        } else {
            const mobileNumberPattern = /^\d{10}$/;
            if (!mobileNumberPattern.test(phone)) {
                formIsValid = false;
                setPhoneError("Please enter a valid 10-digit mobile number.");
            } else {
                setPhoneError("");
            }
        }

        if (!username) {
            formIsValid = false;
            setNameError("Please enter your name.");
        } else {
            const namePattern = /^[a-zA-Z\s]+$/;
            if (!namePattern.test(username)) {
                formIsValid = false;
                setNameError("Please enter a valid name.");
            } else {
                setNameError("");
            }
        }

        return formIsValid;
    };

    const onSubmitOtp = async (e) => {
        e.preventDefault();
        if (!handleFormValidationOtp()) return;
        const data = {
            mobileNumber: phone,
            enteredOTP: parseInt(otp.join(''))
        }
        const response = await TripApi.verifyOTP(data);
        if (!response.status) {
            setOtpError("Incorrect OTP, please check and try again.");
            return;
        };
        if (response.code === 401) {
            setOtpError("OTP expired, please request new OTP.");
            return;
        }
        setCustomerId(response.id);
        setVisibleSummary(true);
        setVisibleOtp(false);
    };

    const handleFormValidationOtp = () => {
        let formIsValid = true;
        const formattedOtp = parseInt(otp.join(''));

        if (!formattedOtp) {
            formIsValid = false;
            setOtpError("Please enter the 4-digit OTP.");
        } else {
            const otpPattern = /^\d{4}$/;
            if (!otpPattern.test(formattedOtp)) {
                formIsValid = false;
                setOtpError("Please enter a valid 4-digit OTP.");
            } else {
                setOtpError("");
            }
        }
        return formIsValid;
    };

    const handleBookNowClick = async () => {
        const dateOfRide = DateUtils.getExDateFormat(props.pickUpDate);
        const data = {
            pickUpLocation: props.pickupLocation,
            dropLocation: props.destinationLocation,
            dateOfRide: DateUtils.convertDateReverse(dateOfRide),
            pickUpTime: props.pickUpTime,
            carType: props.carType,
            distanceInKm: props.distance,
            totalFare: props.estimatedRateWithDriver,
            tripStatus: 1,
            tripMode: props.tripMode,
            customerId: parseInt(customerId),
            bookingModeId: 2, // website
            farePerKm: props.fare,
            pickupLocationUrl: pickupLocationMapUrl,
            dropLocationUrl: dropLocationMapUrl,
            userCreatedBy: 2 // website
        };
        const response = await TripApi.bookRide(data);
        if (response.code !== 200) return;

        const templateParamsForWhatsapp = [
            String(username),
            String(props.pickupLocation),
            String(props.destinationLocation),
            String(props.pickUpDate),
            String(props.pickUpTime),
            String(response.data.startOtp),
            String(response.data.endOtp),
            String(response.data.rideId),
            String(props.smallCardCarName),
            "Local Trip",
            String(props.distance),
            String(props.estimatedRateWithDriver),
        ];
        // const responseWhatsapp = WhatsappApi.sendMessage({ destination: phone, templateParams: templateParamsForWhatsapp });
        // if (responseWhatsapp.success) {
        //     message.success("Booking details sent your Whatsapp.");
        // };
        const templateParamsForWhatsappAdmin = [
            String(response.data.rideId),
            String(props.pickupLocation),
            String(props.destinationLocation),
            String(props.pickUpDate),
            String(props.pickUpTime),
            String(props.smallCardCarName),
            "Local Trip",
            String(props.distance),
            String(props.driverFee),
            String(phone),
            String(props.carPrice),
            String(props.estimatedRateWithDriver),
        ];
        // const responseWhatsappAdmin1 = WhatsappApi.sendMessageToAdmin1({ templateParams: templateParamsForWhatsappAdmin });
        // const responseWhatsappAdmin2 = WhatsappApi.sendMessageToAdmin2({ templateParams: templateParamsForWhatsappAdmin });

        // console.log("Response for Admin1: ", responseWhatsappAdmin1);
        // console.log("Response for Admin2: ", responseWhatsappAdmin2);

        setBookingId(response.data.rideId);
        window.scrollTo(0, 0);
        setConfetti(true);
        setTimeout(() => setConfetti(false), 6000);
        setVisibleConfirm(true);
        setVisibleModal(false);
    };

    useEffect(() => {
        if (visibleLogin) {
            setVisibleModal(false);
        }
        if (visibleOtp) {
            setVisibleLogin(false);
        }
        if (visibleSummary) {
            setVisibleOtp(false);
        }
        if (visibleConfirm) {
            setVisibleSummary(false);
        }
    }, [visibleLogin, visibleOtp, visibleSummary, visibleConfirm]);
    const handleClose = () => {
        setVisibleModal(false);
        setVisibleLogin(false);
        setVisibleOtp(false);
        setVisibleSummary(false);
        setVisibleConfirm(false);
    };

    const pickupLocation = props.pickupLocation;

    useEffect(() => {
        // Countdown timer logic
        const countdown = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000);
        if (timer === 0) {
            clearInterval(countdown);
        }
        return () => clearInterval(countdown);
    }, [visibleOtp]);

    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${mins.toString().padStart(2, "0")}:${secs
            .toString()
            .padStart(2, "0")}`;
    };

    const handleResend = async () => {
        const data = {
            userName: username,
            mobileNumber: phone
        }
        const response = await TripApi.getOTP(data);
        if (!response.status) return;
        message.success("OTP resent successfully");
    };

    return (
        <>
            <div
                id="scrollControl"
                className=" bg-[#222831] mx-0 lg:mx-5 rounded-2xl mt-10 mb-20 pt-2 pb-2 md:hover:transform md:hover:scale-105 transition duration-300 ease-in-out"
            >
                {confetti && <Confetti gravity={0.1} className='w-full' />}
                <div className="mt-6 mb-4 text-center">
                    <span className="font-sans text-2xl font-semibold text-white">
                        {props.cardNameSpan}
                    </span>
                </div>
                <div className="">
                    <div className="text-center">
                        <div className="bg-[#00ADB5]  lg:ml-[250px] ml-[200px]  rounded-tl-3xl text-white p-1 font-sans font-medium text-base">
                            Fare: <span className="text-lg font-semibold">{props.fare}</span>
                            /KM
                        </div>
                    </div>
                    <div className="">
                        <img
                            className="w-[230px] h-[120px] mx-auto"
                            src={props.carImage}
                            alt="sedan-icon"
                        />
                    </div>
                </div>
                <div class="lg:hidden">
                    <div className="m-4 bg-white rounded-md">
                        <div className="flex items-center justify-between w-full px-4 mb-4">
                            <div className="mt-4 ">
                                <label
                                    className="text-[#222831]   pl-1  font-sans font-medium "
                                    htmlFor="distance"
                                >
                                    Distance:
                                </label>
                                <h1 className="text-[#8B1874] text-center font-bold uppercase ">
                                    {props.distance} KM
                                </h1>
                            </div>
                            <div className="pl-2 mt-4 ">
                                <div className="flex items-center ">
                                    <div className="relative flex items-center group">
                                        <img className="w-5 h-5 mx-[1px]" src={iIcon} alt="" />
                                        <div className="absolute hidden group-hover:block top-8 -right-[110px] rounded-lg px-2 py-1 h-auto w-[200px] text-white font-bold text-[9px] bg-[#000000f7]">
                                            Total fare may change at the end of your trip if the distance travelled exceeds the estimated distance of {" "}
                                            {props.distance <= MIN_KM_LOCAL_TRIP ? MIN_KM_LOCAL_TRIP : props.distance}km
                                        </div>
                                        <div className="absolute  hidden group-hover:flex justify-center top-[18px] -right-[90px] rounded-lg px-2 py-1 h-auto w-[200px] text-[10px]">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-caret-up-fill"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                            </svg>
                                        </div>
                                    </div>{" "}
                                    <label
                                        className="text-[#222831]  flex text-base font-[600]"
                                        htmlFor="estimatedFare"
                                    >
                                        Estimated Fare:
                                    </label>
                                </div>
                                <h1 className="text-[#8B1874] pl-8 font-bold  text-[22px] ">
                                    ₹ {props.estimatedRateWithDriver?.toLocaleString()}
                                </h1>
                            </div>
                        </div>
                        <div className="mb-4">
                            <span className="text-[#FF1E1E] font-sans font-medium text-sm md:text-base ml-2">
                                *Toll/Parking charges Extra*{" "}
                            </span>
                        </div>
                        <div className="flex justify-center w-full mb-6">
                            <div className="w-[108px] l   items-center border-2  shadow-lg   lg:w-auto px-4 text-xs my-1 flex bg-[#222831] justify-center mx-2 py-2   shadow-gray-700  border-white bottom-2   rounded-xl">
                                <img
                                    className="w-5 h-5 my-auto"
                                    src={taxiiconwhite}
                                    alt="taxi-icon"
                                />
                                <span className="pl-1 text-xs font-semibold text-white lg:pl-2">
                                    {props.smallCardCarName}
                                </span>
                            </div>
                            <div className="min-w-[120px] shadow-lg shadow-gray-700  border-white bottom-2   rounded-xl items-center border-2     px-4 text-xs my-1 bg-[#222831] justify-center flex py-2">
                                <img
                                    className="w-5 h-5 my-auto"
                                    src={person}
                                    alt="person-icon"
                                />
                                <span className="w-full pl-2 text-white">
                                    {props.smallCardNoofPerson}
                                </span>
                            </div>
                            <div className="w-auto shadow-lg shadow-gray-700  border-white bottom-2   rounded-xl   items-center border-2  lg:w-auto px-4 text-xs my-1 bg-[#222831] justify-center flex mx-2 py-2">
                                <img className="w-5 h-5 my-auto" src={ac} alt="ac-icon" />
                                <span className="pl-2 text-white">A/C</span>
                            </div>
                        </div>
                        <div className="text-center">
                            <button
                                onClick={() => {
                                    setVisibleModal(true);
                                }}
                                className="bg-[#00ADB5] shadow-lg shadow-gray-700  border-[#00adb5]  py-2 px-10 mb-6 font-sans text-md font-medium rounded-lg hover:bg-[#10666b]  text-white"
                            >
                                Book Now
                            </button>
                        </div>
                    </div>
                </div>
                <div class="hidden lg:block">
                    <div className="m-4 bg-white rounded-md">
                        <div className="flex flex-wrap items-center justify-between w-full px-10 mb-2 pl-7">
                            <div className="mt-4 ">
                                <label
                                    className="text-[#222831] flex  text-md font-[600]"
                                    htmlFor="distance"
                                >
                                    Distance:
                                </label>
                                <h1 className="text-[#8B1874] font-bold uppercase text-lg  ">
                                    {props.distance} KM
                                </h1>
                            </div>
                            <div className="pl-1 mt-4 ">
                                <div className="flex ">
                                    <div className="relative flex items-center group">
                                        <img className="w-5 h-5 mx-[1px]" src={iIcon} alt="" />
                                        <div className="absolute hidden group-hover:block top-8 -right-[110px] rounded-lg px-2 py-1 h-auto w-[270px] text-white font-bold text-[9px] bg-[#000000f7]">
                                            Total fare may change at the end of your trip if the distance travelled exceeds the estimated distance of {" "}
                                            {props.distance <= 20 ? 20 : props.distance}km
                                        </div>
                                        <div className="absolute  hidden group-hover:flex justify-center top-[18px] -right-[90px] rounded-lg px-2 py-1 h-auto w-[200px] text-[10px]">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-caret-up-fill"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <label
                                        className="text-[#222831]  flex text-base font-[600]"
                                        htmlFor="estimatedFare"
                                    >
                                        Estimated Fare:
                                    </label>
                                </div>
                                <h1 className="text-[#8B1874] pl-8 font-bold  text-[22px] ">
                                    ₹ {props.estimatedRateWithDriver?.toLocaleString()}
                                </h1>
                            </div>
                        </div>
                        <div className="mb-2">
                            <span className="text-[#FF1E1E] font-sans pl-4 font-bold text-sm  ml-2">
                                *Toll/Parking charges Extra*
                            </span>
                        </div>
                        <div className="flex justify-between w-full mb-6">
                            <div className="w-[108px] l   items-center border-2  shadow-lg   lg:w-[108px] px-4 text-sm my-1 flex bg-[#222831] justify-center mx-2 py-2   shadow-gray-700  border-white bottom-2   rounded-lg">
                                <img
                                    className="w-5 h-5 my-auto"
                                    src={taxiiconwhite}
                                    alt="taxi-icon"
                                />
                                <span className="pl-1 lg:pl-2  text-sm     font-[600]  text-white">
                                    {props.smallCardCarName}
                                </span>
                            </div>
                            <div className="w-[125px] shadow-lg shadow-gray-700  border-white bottom-2 rounded-lg items-center border-2 px-4 text-xs my-1 bg-[#222831] justify-center flex  py-2">
                                <img
                                    className="w-5 h-5 my-auto"
                                    src={person}
                                    alt="person-icon"
                                />
                                <span className="pl-2 text-white font-[600] w-full">
                                    {props.smallCardNoofPerson}
                                </span>
                            </div>
                            <div className="w-auto shadow-lg shadow-gray-700  border-white bottom-2   rounded-lg   items-center border-2  lg:w-auto px-4 text-sm my-1 bg-[#222831] justify-center flex mx-2 py-2">
                                <img className="w-5 h-5 my-auto" src={ac} alt="ac-icon" />
                                <span className="pl-2 text-white font-[600]">A/C</span>
                            </div>
                        </div>
                        <div className="text-center">
                            <button
                                onClick={() => {
                                    setVisibleModal(true);
                                }}
                                className="bg-[#00ADB5] shadow-lg shadow-gray-700  border-[#00adb5]  py-2 px-10 mb-6 font-sans text-md font-medium rounded-lg hover:bg-[#10666b]  text-white"
                            >
                                Book Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="scroll-container" ref={scrollContainerRef}>
                {visibleModal && (
                    <div className="absolute left-0 z-40 w-full bg-black bg-opacity-0 h-5/6 -top-0">
                        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                            <div className="rounded-lg bg-[#fff] max-w-[400px]">
                                <div className="flex flex-col card-header bg-[#eeeeee] w-full pb-6 rounded-lg">
                                    <div className="flex w-full mt-1 mb-3">
                                        <div className="flex justify-center w-full text-center ">
                                            <span className="font-sans text-2xl font-bold text-black">
                                                {props.cardNameSpan}
                                            </span>
                                        </div>
                                    </div>
                                    <img
                                        src={props.carImage}
                                        alt="rover"
                                        className="w-[230px] h-[120px] mx-auto"
                                    />
                                </div>
                                <div className="flex flex-col px-4 py-6 rounded-md card-body">
                                    <div className="flex items-center justify-between">
                                        <p className="font-semibold text-black text-end ">
                                            Distance:
                                            <span className="text-[#8B1874] uppercase font-bold pl-1 text-base ">
                                                {props.distance} KM
                                            </span>
                                        </p>
                                        <p className="w-4/6 pl-5 font-semibold text-black lg:w-4/6 lg:pl-5 ">
                                            Total Estimated Fare:
                                            <span className="text-[#8B1874] relative font-bold pl-1 text-lg ">
                                                ₹ {props.estimatedRateWithDriver?.toLocaleString()}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="flex items-center mt-6">
                                        <div class="rounded-full  flex justify-center items-center w-4 h-4  border-2 bg-[#039855] ">
                                            <img className="w-4/6 h-4/6" src={success} alt="" />
                                        </div>
                                        <p className="pl-1 text-xs text-gray-600">
                                            Excludes Toll Costs, Parking and State Tax.
                                        </p>
                                    </div>
                                    <div className="flex items-center">
                                        <div class="rounded-full  flex justify-center items-center w-4 h-4  border-2 bg-[#039855] ">
                                            <img className="w-4/6 h-4/6" src={success} alt="" />
                                        </div>
                                        <p className="pl-1 text-xs text-gray-600">
                                            ₹{props.fare}/kilometre will be charged for Extra
                                            Kilometre.
                                        </p>
                                    </div>
                                    <div className="flex items-start">
                                        <div class="rounded-full  flex justify-center items-center w-4 h-4  border-2 bg-[#039855] ">
                                            <img className="w-4/6 h-4/6" src={success} alt="" />
                                        </div>
                                        <p className="pl-1 text-xs text-gray-600">
                                            Extra fare may apply if you don’t end trip at{" "}
                                            {props.destinationLocation}.{" "}
                                        </p>
                                    </div>
                                    <div className="flex items-center mb-5">
                                        <div class="rounded-full  flex justify-center items-center w-4 h-4  border-2 bg-[#F79009] ">
                                            <img className="w-4/6 h-4/6" src={alert} alt="" />
                                        </div>
                                        <p className="pl-1 my-3 text-xs font-semibold text-red-500">
                                            *Toll/Parking charges Extra*
                                        </p>
                                    </div>

                                    <div className="flex justify-between w-full">
                                        <div className="w-[108px]  items-center border-2  shadow-lg   lg:w-auto px-4 text-sm my-1 flex bg-[#222831] justify-center mx-2 py-2   shadow-gray-700  border-white bottom-2   rounded-lg">
                                            <img
                                                className="w-5 h-5 my-auto"
                                                src={taxiiconwhite}
                                                alt="taxi-icon"
                                            />
                                            <span className="pl-1 lg:pl-2  text-sm  font-[600]  text-white">
                                                {props.smallCardCarName}
                                            </span>
                                        </div>
                                        <div className="min-w-[120px] shadow-lg shadow-gray-700  border-white bottom-2   rounded-lg items-center border-2 px-4 text-sm my-1 bg-[#222831] justify-center flex  py-2">
                                            <img
                                                className="w-5 h-5 my-auto"
                                                src={person}
                                                alt="person-icon"
                                            />
                                            <span className="pl-2 text-white font-[600] w-full">
                                                {props.smallCardNoofPerson}
                                            </span>
                                        </div>
                                        <div className="w-auto shadow-lg shadow-gray-700  border-white bottom-2   rounded-lg   items-center border-2  lg:w-auto px-4 text-sm my-1 bg-[#222831] justify-center flex mx-2 py-2">
                                            <img className="w-5 h-5 my-auto" src={ac} alt="ac-icon" />
                                            <span className="pl-2 text-white font-[600]">A/C</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex pt-1 mb-8 justify-center">
                                    <button
                                        onClick={() => handleClose()}
                                        variant="text"
                                        color="error"
                                        className="bg-[#fff] py-1 px-8 mr-4  border-gray-400 border  font-sans text-md font-medium rounded-md text-gray-700"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        variant="text"
                                        className="bg-[#00ADB5] py-1 px-8 hover:bg-teal-700  border-white font-sans text-md font-medium rounded-md text-white"
                                        onClick={() => {
                                            setVisibleLogin(true);
                                            setVisibleModal(false);
                                        }}
                                    >
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {visibleLogin && (
                    <>
                        <div className="fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50">
                            <div className="max-w-md mt-10 z-40  px-6 rounded-lg w-[330px] h-[420px] bg-[#fff]">
                                <div className=" flex w-full z-40 justify-end ml-3">
                                    <button
                                        onClick={handleClose}
                                        className="mt-2  text-xl -top-5 font-bold text-center text-white bg-red-300 border-none rounded-full w-7 h-7 bg-none"
                                    >
                                        <img src={close} alt="" />
                                    </button>
                                </div>
                                <div className="flex w-full  mb-3">
                                    <div className="flex items-center justify-around w-full px-5">
                                        <img
                                            className="w-[60px] h-[40px]"
                                            src={CClogo}
                                            alt="logo"
                                        />

                                        <h1 className="text-[#00ADB5]   pt-2 text-[24px] font-[650] text-center">
                                            Chennai Cabs
                                        </h1>
                                    </div>
                                </div>

                                <div class="text-black text-center mt-4 text-base font-roboto font-medium leading-7">
                                    Welcome to Chennai Cabs!
                                </div>
                                <form onSubmit={onSignInSubmit}>
                                    <div className="relative flex flex-col items-center justify-start w-full gap-1 mt-5">
                                        <div className="text-sm font-medium text-[#344053] self-start pl-[1px]">
                                            Username
                                        </div>
                                        <div className="shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] overflow-hidden bg-white relative w-full h-10 shrink-0 items-center border rounded-lg">
                                            <input
                                                type="text"
                                                id="username"
                                                value={username}
                                                onChange={(event) => setUsername(event.target.value)}
                                                className="px-2.5 border-solid border-[#cfd4dc] h-[40px] text-left border rounded-lg w-full mr-1 text-[14px] bg-white outline-none text-[#393E46] font-sans font-medium text-md pl-2"
                                                placeholder="Username"
                                            />
                                        </div>
                                    </div>
                                    {nameError && (
                                        <p className=" text-red-500 text-sm ">{nameError}</p>
                                    )}
                                    <div className="relative flex flex-col items-center justify-start w-full gap-1 mt-5">
                                        <div className="text-sm font-medium text-[#344053] self-start pl-[1px]">
                                            Phone number
                                        </div>
                                        <div
                                            className=" flex  border-solid border-[#cfd4dc] h-[40px] text-left border-b border rounded-lg  w-full
                                      mr-1 text-[14px] bg-[#fff] outline-none text-[#393E46] font-sans font-medium text-md overflow-hidden"
                                        >
                                            <div
                                                className=" flex justify-center items-center px-2.5 border-solid b border-r-[#cfd4dc] h-[38px] text-left border-r  w-2/12
                      mr-1 text-[14px] bg-[#f9f9f9] outline-none text-[#393E46] font-sans font-medium text-md pl-2"
                                            >
                                                +91
                                            </div>
                                            <input
                                                type="tel"
                                                id="phone"
                                                value={phone}
                                                maxLength={10}
                                                onChange={(event) => setPhone(event.target.value)}
                                                className="   h-[38px] text-left mb-1  border-none  w-11/12 mr-1 text-[14px] outline-none text-[#393E46] font-sans font-medium text-md pl-2"
                                                placeholder="00000 00000"
                                            />
                                        </div>
                                    </div>
                                    {phoneError && (
                                        <p className=" text-red-500 text-sm ">{phoneError}</p>
                                    )}
                                    <div class=" flex justify-center items-center mt-8 mb-6">
                                        <button
                                            type="submit"
                                            className="text-base rounded-md bg-teal-500 hover:bg-[#0d8288] font-semibold text-white h-9 w-36 font-roboto"
                                        >
                                            Get OTP
                                        </button>
                                    </div>
                                    <div
                                        id="sign-in-button"
                                        style={{ visibility: "hidden", display: "none" }}
                                    ></div>
                                </form>
                            </div>
                        </div>
                    </>
                )}
                {visibleOtp && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="max-w-md mt-10 px-6 rounded-lg w-[330px] bg-[#fff]">
                            <form onSubmit={onSubmitOtp}>
                                <div className=" flex w-full justify-end ml-3">
                                    <button
                                        onClick={handleClose}
                                        className="mt-2  text-xl -top-5 font-bold text-center text-white bg-red-300 border-none rounded-full w-7 h-7 bg-none"
                                    >
                                        <img src={close} alt="" />
                                    </button>
                                </div>
                                <div className="flex w-full justify-around  px-4  mb-3">
                                    <div className="flex items-center justify-between w-full px-1">
                                        <img
                                            className="w-[60px] h-[40px]"
                                            src={CClogo}
                                            alt="logo"
                                        />
                                        <h1 className="text-[#00ADB5]  w-full   pt-2 text-[24px] font-[650] text-center">
                                            Chennai Cabs
                                        </h1>
                                    </div>
                                </div>

                                <div class="text-black mt-7 text-center text-[14px] font-roboto font-medium w-full">
                                    Enter the OTP sent to your mobile number.
                                </div>
                                <div className="relative flex items-center justify-center w-full gap-3 mt-3">
                                    <OTPField
                                        emailOtp={otp}
                                        setEmailOtp={setOtp}
                                    />
                                </div>
                                {otpError && (
                                    <p className=" text-red-500 text-sm mt-1 pl-3">{otpError}</p>
                                )}
                                <div className="text-black text-center mt-3 text-sm font-roboto font-medium w-full">
                                    Didn't receive OTP?
                                    {timer > 0 ? (
                                        <button className="pl-1 font-bold" disabled>
                                            Request again in {formatTime(timer)}
                                        </button>
                                    ) : (
                                        <button className="pl-1 font-bold" onClick={handleResend}>
                                            Resend again
                                        </button>
                                    )}
                                </div>
                                <div className="relative flex flex-col items-center justify-start w-full gap-1 mt-3 mb-5">
                                    <div class="rounded-md bg-teal-500 hover:bg-teal-700 flex justify-center items-center mt-4">
                                        <button
                                            type="submit"
                                            className="text-base font-semibold text-white w-36 h-9 font-roboto"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                )}

                {visibleSummary && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="max-w-md mt-10 w-full rounded-lg    mx-[250px]     bg-[#fff]">
                            <div className=" flex w-full justify-end pr-2 ">
                                <button
                                    onClick={handleClose}
                                    className="mt-2  text-xl -top-5 font-bold text-center text-white bg-red-300 border-none rounded-full w-7 h-7 bg-none"
                                >
                                    <img src={close} alt="" />
                                </button>
                            </div>
                            <div className="flex w-full  mb-3">
                                <div className="flex justify-center w-full text-center ">
                                    <div className="  text-2xl font-bold text-[#00adb5]  ">
                                        Booking Summary
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-between px-6 pt-5">
                                <div className="flex flex-col items-center justify-center ">
                                    <img src={location} className="w-4 h-4 " alt="" />

                                    <span className="flex items-center text-center justify-center px-1 pt-1 text-sm font-semibold text-black">
                                        {props.pickupLocation}
                                    </span>
                                </div>
                                <div className="flex flex-col items-center justify-center">
                                    <img src={taxiicon} className="w-4 h-4 " alt="" />

                                    <span className="flex items-center text-center justify-center px-1 pt-1 text-sm font-semibold text-black">
                                        {props.destinationLocation}
                                    </span>
                                </div>
                            </div>
                            <div className="px-4 mb-4 mt-7">
                                <div className="flex justify-between px-4">
                                    <div className="flex">
                                        <img src={calender} className="w-4 h-4 " alt="" />
                                        <span className="flex items-center justify-center pl-1 text-sm font-semibold text-black">
                                            {formattedDate}
                                        </span>
                                    </div>
                                    <div className="flex">
                                        <img src={taxiicon} className="w-4 h-4 " alt="" />
                                        <span className="flex items-center justify-center pl-1 text-sm font-semibold text-black">
                                            Local Trip
                                        </span>
                                    </div>
                                </div>
                                <div className="flex justify-between w-full mt-6">
                                    <div className="w-[108px] l   items-center border-2  shadow-lg   lg:w-auto px-4 text-sm my-1 flex bg-[#222831] justify-center mx-2 py-2   shadow-gray-700  border-white bottom-2   rounded-lg">
                                        <img
                                            className="w-5 h-5 my-auto"
                                            src={taxiiconwhite}
                                            alt="taxi-icon"
                                        />
                                        <span className="pl-1 lg:pl-2  text-sm     font-[600]  text-white">
                                            {props.smallCardCarName}
                                        </span>
                                    </div>
                                    <div className="w-[125px] shadow-lg shadow-gray-700  border-white bottom-2   rounded-lg items-center border-2     px-4 text-sm my-1 bg-[#222831] justify-center flex  py-2">
                                        <img
                                            className="w-5 h-5 my-auto"
                                            src={person}
                                            alt="person-icon"
                                        />
                                        <span className="pl-2 text-white font-[600] w-full">
                                            {props.smallCardNoofPerson}
                                        </span>
                                    </div>
                                    <div className="w-auto shadow-lg shadow-gray-700  border-white bottom-2   rounded-lg   items-center border-2  lg:w-auto px-4 text-sm my-1 bg-[#222831] justify-center flex mx-2 py-2">
                                        <img className="w-5 h-5 my-auto" src={ac} alt="ac-icon" />
                                        <span className="pl-2 text-white font-[600]">A/C</span>
                                    </div>
                                </div>
                                <hr className="mt-5 mb-1 border-gray-300 shadow-lg mt" />
                                <div className=" mt-4  px-[10px]">
                                    <div className="flex items-center justify-between text-lg font-semibold text-gray-700 ">
                                        <div>Distance</div>
                                        <div className="text-[#8B1874] uppercase  text-lg font-bold pl-1  ">
                                            {props.distance} KM
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=" bg-[#eee]  rounded-b-lg  w-full h-full">
                                <div className="flex items-center justify-between px-4 pt-4">
                                    <div className="text-2xl font-semibold ">
                                        Total Estimated Fare
                                    </div>
                                    <div className="text-[#00ADB5] relative font-bold pl-1  text-2xl ">
                                        ₹ {props.estimatedRateWithDriver?.toLocaleString()}
                                    </div>
                                </div>
                                <div className="flex justify-center py-6">
                                    <button
                                        onClick={handleBookNowClick}
                                        className="bg-[#00ADB5] py-2 hover:bg-teal-700 px-10 font-sans text-md font-medium rounded-md text-white"
                                    >
                                        Confirm Booking
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {visibleConfirm && (
                    <div className="fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="max-w-[400px] mt-10 z-20 px-6 rounded-lg pb-3 mx-2 bg-[#fff]">
                            <div className="flex items-center z-20 justify-center w-full">
                                <div className="flex justify-center  mt-10 bg-[#039855] rounded-full w-20 h-20 items-center">
                                    <img
                                        className=" w-14 bg-[#12B76A] rounded-full  p-2 h-14  "
                                        src={success}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="flex justify-center w-full mt-5">
                                <h1 className="text-2xl font-bold text-[#03C988]">
                                    Get Ready for the Ride !
                                </h1>
                            </div>
                            <div className="mt-6 text-center text-[#393E46] text-[17px]">
                                <p className="font-semibold flex flex-col">
                                    <span>Hi
                                        <span className=" font-bold text-black pl-1 ">
                                            {username}
                                        </span>
                                        , your booking has been
                                    </span>
                                    <span>
                                        <span className="">confirmed with Booking ID:</span>
                                        <span className=" font-bold text-black pl-1 ">
                                            {bookingId}
                                        </span>
                                    </span>
                                </p>
                                <p className="font-semibold">
                                    Please ensure that you have received a confirmation message on <span className="text-[#12B76A] pr-1 font-bold"> Whatsapp.</span>
                                    <span className="flex flex-col">
                                        If not, please let us know.</span>
                                </p>
                            </div>
                            <div className="flex items-center justify-center w-full mt-5 contact">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        d="M15.0499 5C16.0267 5.19057 16.9243 5.66826 17.628 6.37194C18.3317 7.07561 18.8094 7.97326 18.9999 8.95M15.0499 1C17.0792 1.22544 18.9715 2.13417 20.4162 3.57701C21.8608 5.01984 22.7719 6.91101 22.9999 8.94M21.9999 16.92V19.92C22.0011 20.1985 21.944 20.4742 21.8324 20.7293C21.7209 20.9845 21.5572 21.2136 21.352 21.4019C21.1468 21.5901 20.9045 21.7335 20.6407 21.8227C20.3769 21.9119 20.0973 21.9451 19.8199 21.92C16.7428 21.5856 13.7869 20.5341 11.1899 18.85C8.77376 17.3147 6.72527 15.2662 5.18993 12.85C3.49991 10.2412 2.44818 7.27099 2.11993 4.18C2.09494 3.90347 2.12781 3.62476 2.21643 3.36162C2.30506 3.09849 2.4475 2.85669 2.6347 2.65162C2.82189 2.44655 3.04974 2.28271 3.30372 2.17052C3.55771 2.05833 3.83227 2.00026 4.10993 2H7.10993C7.59524 1.99522 8.06572 2.16708 8.43369 2.48353C8.80166 2.79999 9.04201 3.23945 9.10993 3.72C9.23656 4.68007 9.47138 5.62273 9.80993 6.53C9.94448 6.88792 9.9736 7.27691 9.89384 7.65088C9.81408 8.02485 9.6288 8.36811 9.35993 8.64L8.08993 9.91C9.51349 12.4135 11.5864 14.4864 14.0899 15.91L15.3599 14.64C15.6318 14.3711 15.9751 14.1858 16.3491 14.1061C16.723 14.0263 17.112 14.0555 17.4699 14.19C18.3772 14.5286 19.3199 14.7634 20.2799 14.89C20.7657 14.9585 21.2093 15.2032 21.5265 15.5775C21.8436 15.9518 22.0121 16.4296 21.9999 16.92Z"
                                        stroke="#101828"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>

                                <span className="text-[#393E46] pl-4 font-bold text-lg">
                                    <a href="tel:+919841346080">98413 46080</a>
                                </span>
                            </div>
                            <div className="flex justify-center mt-7 mb-5">
                                <button
                                    onClick={handleClose}
                                    className="bg-[#12B76A] py-1 px-7 font-sans text-md font-medium rounded-md text-white"
                                >
                                    <Link to={"/"}>Done</Link>
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}