import React, { useState, useEffect } from "react";
import Layout from "../layouts/PageLayout";
import sedan from "../assets/images/Sedan-img.png";
import suvPlus from "../assets/images/suvPlus.png";
import suv from "../assets/images/suv-img.png";
import RoundTripCarCard from "../components/RoundTripCarCard";
import innova from "../assets/images/innova.com.png";
import tempo from "../assets/images/tempo.png";
import Backbutton from "../components/BackButton";
import TripInput from "../components/TripInput";
import { useSearchParams } from "react-router-dom";
import TripApi from "../services/api/tripApi";
import loading from "../assets/icon/loading.gif";
import RentalTripCarCard from "../components/RentalTripCarCard";
import dayjs from "dayjs";
import DateUtils from "../services/util/DateUtils";

function RentalTrip() {
  const [params] = useSearchParams();
  const pickupLocation = params.get('pickup');
  const pickUpDate = params.get('pickupDate');
  const pickUpTime = params.get('pickupTime');
  const distance = params.get('distance');
  const duration = params.get('duration');

  const [tripData, setTripData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchDetails();
  }, [params]);

  const fetchDetails = async () => {
    setIsLoading(true);
    const data = {
      pickupLocation: pickupLocation,
      date: pickUpDate,
      time: pickUpTime,
      tripMode: 3,
      rentalDistance: distance,
      rentalDuration: duration
    }
    const currentTimePlusMinutes = dayjs().add(0, 'minutes'); // minutes ahead
    const currentDate = dayjs().startOf("day");

    if (dayjs(pickUpTime, 'HH:mm').isBefore(currentTimePlusMinutes)) {
      if (DateUtils.convertDate(pickUpDate) === DateUtils.getCurrentDateFormatMonth()) {
        setIsLoading(false);
        return;
      }
    }
    if (dayjs(pickUpDate).isBefore(currentDate)) {
      setIsLoading(false);
      return;
    }
    const response = await TripApi.getDistance(data);
    setIsLoading(false);
    if (response.code !== 200) return;
    setTripData(response.data);
  };

  const getCarImage = (car) => {
    if (car === "sedan") return sedan;
    if (car === "suv") return suv;
    if (car === "suv+") return suvPlus;
    if (car === "tempo") return tempo;
    if (car === "muv") return innova;
  };

  return (
    <Layout>
      <div className="px-4 md:px-10">
        <Backbutton />
        <div className="my-3 md:my-10 text-center">
          <span className="text-[#00ADB5] text-2xl font-bold font-sans">
            Rental Trip Booking
          </span>
        </div>
        <TripInput
          prePickupLocation={pickupLocation}
          prePickUpTime={pickUpTime}
          prePickUpDate={pickUpDate}
          preDistance={distance}
          preDuration={duration}
        />
        <div className="w-full flex flex-wrap justify-around">
          {isLoading ?
            <img src={loading} alt="Loading..." />
            :
            <>
              {tripData && tripData?.map((item, index) => {
                return (
                  <div className="w-full md:w-auto max-w-[400px]">
                    <RentalTripCarCard
                      key={index}
                      cardNameSpan={item.modelName}
                      carImage={getCarImage(item.carModeName?.toLowerCase())}
                      smallCardCarName={item.carModeName}
                      carType={parseInt(item.carModeId)}
                      smallCardNoofPerson={item.capacity}
                      distance={distance}
                      duration={duration}
                      // estimatedRate={item.estimatedRate}
                      index={item.carModeName}
                      pickupLocation={pickupLocation}
                      // destinationLocation={destinationLocation}
                      pickUpTime={pickUpTime}
                      pickUpDate={pickUpDate}
                      // dropDate={dropDate}
                      // daysDiff={item.days}
                      // distanceUpto={item.distanceUpto}
                      estimatedRateWithDriver={item.estimatedRateWithDriverFee}
                      // driverFee={item.totalDriverFee}
                      fare={item.farePerKm}
                      tripMode={3}
                    />
                  </div>
                )
              })}
            </>
          }
        </div>
      </div>
    </Layout>
  );
}

export default RentalTrip;